import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select"
import { parameterize } from "../components/utilities"

export default class extends Controller {
  static targets = ['fieldsSelect', 'fieldsWrapper', 'fieldsTpl', 'dataTypeInput', 'dataTypeInstruction', 'submitBtn']

  connect(){
    this.initializeFieldsSelect()

    this.updateOptions()
  }

  initializeFieldsSelect(){
    this.fieldsSelectInstance = initializeSelect({
      selector: this.fieldsSelectTarget,
      config: {
        addable: true, 
        closeAfterSelect: true
      },
      multiple: false,
      otherSettings: {
        options: JSON.parse(this.fieldsSelectTarget.dataset.options),
        create: (input) => {
          // Format the new item as a JSON string
          const newValue = JSON.stringify({
            field_name: input,
            field_id: '', 
            type: '', 
            field_db_name: ''
          });
  
          return {
            value: newValue, // This is the internal value
            text: input, // This is the visible label
          };
        },
        onItemAdd: (value) => {
          this.addField()
        }
      }
    })
  }

  updateOptions(){
     // SELECTED FIELDS
    const selectedFields = []
    document.querySelectorAll('[data-additional-tag]').forEach(tag => {
      selectedFields.push(tag.dataset.fieldId)
    })

    const initialOptions = JSON.parse(this.fieldsSelectTarget.dataset.options);
    const optionsToDelete = initialOptions.filter(option => {
      const fieldId = JSON.parse(option['value'])['field_id']
      return selectedFields.includes(fieldId)
    })
    const valuesToDelete = optionsToDelete.map(option => option['value'])
    valuesToDelete.forEach(value => this.fieldsSelectInstance.removeOption(value))
  }

  // Add fields
  addField(){
    const value = this.fieldsSelectInstance.getValue()
    if (value === '') return

    const parsedValue = JSON.parse(value)

    // Add new tag
    const newTag = this.fieldsTplTarget.innerHTML
                                 .replace(/FIELD_NAME/g, parsedValue['field_name'])
                                 .replace(/FIELD_NAME/ig, parameterize(parsedValue['field_name']))
                                 .replace(/FIELD_ID/g, parsedValue['field_id'])
                                 .replace(/FIELD_DB_NAME/g, parsedValue['field_db_name'])
                                 .replace(/DATA_TYPE/g, parsedValue['type'])

    this.fieldsWrapperTarget.insertAdjacentHTML('beforeend', newTag)

    // Remove from fields Select the option
    this.fieldsSelectInstance.removeOption(value)

    // Update the datatype check
    this.updateFieldsTagState()
  }

  // remove fields
  removeField(){
    event.stopPropagation()

    // Add option to additional select
    const tagToDelete = event.currentTarget.closest('[data-additional-tag]')
    const fieldId = tagToDelete.dataset.fieldId
    const fieldsOptions = JSON.parse(this.fieldsSelectTarget.dataset.options)
    const optionToAdd = fieldsOptions.filter(option => JSON.parse(option['value'])['field_id'] === fieldId)[0]
    this.fieldsSelectInstance.addOption(optionToAdd)

    // Update the datatype check
    this.updateFieldsTagState()
  }

  updateFieldsTagState(){
    if (!this.hasDataTypeInputTarget) {
      this.dataTypeInstructionTarget.classList.add('hidden')
      this.submitBtnTarget.classList.remove('btn-disabled')
      return
    }

    setTimeout(() => {
      const dataTypes = this.dataTypeInputTargets.map(input => {
        // Return the value
        return input.value
      })

      if (dataTypes.some(el => el === '')) {
        this.dataTypeInstructionTarget.classList.remove('hidden')
        this.submitBtnTarget.classList.add('btn-disabled')
      } else {
        this.dataTypeInstructionTarget.classList.add('hidden')
        this.submitBtnTarget.classList.remove('btn-disabled')
      }
    }, 100)
  }
}