import { Controller } from "@hotwired/stimulus"
import { removeContent } from '../../components/utilities';
import setTooltips from '../../components/app_tooltips';

export default class extends Controller {

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  filterDataUpdatedHandler = (event) => this.rebuildTable(event.detail.data);

  connect(){
    // Event Listeners
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    setTooltips(this.element);
  }

  // Remove Event listeners if the controller is removed
  disconnect(){
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
  }

  rebuildTable(data){
    if (!Object.keys(data).includes('refreshed_list')) return;

    const agentType = this.pageState().agent_type;

    let containerId;
    if (agentType === 'deflector'){
      containerId = 'ai_agent_deflector_conversations_index_list'
    } else if (agentType === 'assistant') {
      containerId = 'ai_agent_assistant_automation_histories_index_list'
    } else if (agentType === 'copilot') {
      containerId = 'ai_agent_copilot_conversations_index_list'
    } else {
      containerId = 'ai_agent_admin_automation_histories_index_list'
    }

    const listWrapper = document.querySelector(`#${containerId}`);
    removeContent(listWrapper)

    listWrapper.insertAdjacentHTML('afterbegin', data['refreshed_list'] );
  }
}