import { Controller } from "@hotwired/stimulus"

import cytoscape from 'cytoscape';

import coseBilkent from 'cytoscape-cose-bilkent';
cytoscape.use( coseBilkent );

export default class extends Controller {
  static targets = ['noDataPanel']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Events Handlers
  toggleValidatedNodesHandler = (event) => this.toggleValidatedNodes();
  updateMapHandler = (event) => this.rebuildMap(event.detail.data);
  screenSizeToggledHandler = (event) => this.updateMapAfterResizing(event.detail.screen)
  tabUpdatedHandler = (event) => this.updateMapAfterModeChange(event.detail.mode)
  tagMacroTopicMouseoverHandler = (event) => this.highlightMacroTopic(event.detail.selected_macro_cluster_id)
  tagMacroTopicMouseoutHandler = (event) => this.unhighlightMacroTopic()
  tagMouseoverHandler = (event) => this.highlightTag(event.detail.color, event.detail.cluster_ids)
  tagMouseoutHandler = (event) => this.unhighlightTag()
  knowledgeMouseoverHandler = (event) => this.highlightKnowledge(event.detail.cluster_ids)
  knowledgeMouseoutHandler = (event) => this.unhighlightKnowledge()
  aiAgentMouseoverHandler = (event) => this.highlightAiAgent(event.detail.cluster_ids)
  aiAgentMouseoutHandler = (event) => this.unhighlightAiAgent()
  zoomInHandler = (event) => this.zoomIn()
  zoomOutHandler = (event) => this.zoomOut()
  topicRowClickedHandler = (event) => this.highlightedSelectedTopics()
  resetPageHandler = (event) => this.resetPage()
  filterDataUpdatedHandler = (event) => this.filterMap()
  reverseScaleUpdatedHandler = (event) => this.reverseScale(event.detail.reversed)
  metricRangeUpdatedHandler = (event) => this.updateMetricRange(event.detail.range)

  connect(){
    // Cancel stimulus connect event, if body contains `data-turbolinks-preview` attribute
    // Prevent errors and element flickering when turbolinks loading
    if (document.documentElement.hasAttribute("data-turbolinks-preview"))
      return;
    
    // Events
    window.addEventListener('validated-only-switch-toggled', this.toggleValidatedNodesHandler)
    window.addEventListener('screen-size-toggled', this.screenSizeToggledHandler)
    window.addEventListener('tab-updated', this.tabUpdatedHandler)
    window.addEventListener('map-updated', this.updateMapHandler)
    window.addEventListener('tag-macro-topic-mouseover', this.tagMacroTopicMouseoverHandler)
    window.addEventListener('tag-macro-topic-mouseout', this.tagMacroTopicMouseoutHandler)
    window.addEventListener('tag-mouseover', this.tagMouseoverHandler)
    window.addEventListener('tag-mouseout', this.tagMouseoutHandler)
    window.addEventListener('knowledge-mouseover', this.knowledgeMouseoverHandler)
    window.addEventListener('knowledge-mouseout', this.knowledgeMouseoutHandler)
    window.addEventListener('ai-agent-mouseover', this.aiAgentMouseoverHandler)
    window.addEventListener('ai-agent-mouseout', this.aiAgentMouseoutHandler)
    window.addEventListener('zoom-in', this.zoomInHandler)
    window.addEventListener('zoom-out', this.zoomOutHandler)
    window.addEventListener('topic-row-clicked', this.topicRowClickedHandler)
    window.addEventListener('reset-page', this.resetPageHandler)
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    window.addEventListener('reverse-scale-updated', this.reverseScaleUpdatedHandler)
    window.addEventListener('metric-range-updated', this.metricRangeUpdatedHandler)
    

    // Show table if page_state ok
    if (this.pageState().display === 'map') this.element.classList.remove('hidden')
    if (this.pageState().scale === 'macro') this.element.classList.add('hidden')
    if (this.pageState().scale === 'micro') this.initializeMap()
  }

  disconnect(){
    window.removeEventListener('validated-only-switch-toggled', this.toggleValidatedNodesHandler)
    window.removeEventListener('screen-size-toggled', this.screenSizeToggledHandler)
    window.removeEventListener('tab-updated', this.tabUpdatedHandler)
    window.removeEventListener('map-updated', this.updateMapHandler)
    window.removeEventListener('tag-macro-topic-mouseover', this.tagMacroTopicMouseoverHandler)
    window.removeEventListener('tag-macro-topic-mouseout', this.tagMacroTopicMouseoutHandler)
    window.removeEventListener('tag-mouseover', this.tagMouseoverHandler)
    window.removeEventListener('tag-mouseout', this.tagMouseoutHandler)
    window.removeEventListener('knowledge-mouseover', this.knowledgeMouseoverHandler)
    window.removeEventListener('knowledge-mouseout', this.knowledgeMouseoutHandler)
    window.removeEventListener('zoom-in', this.zoomInHandler)
    window.removeEventListener('zoom-out', this.zoomOutHandler)
    window.removeEventListener('topic-row-clicked', this.topicRowClickedHandler)
    window.removeEventListener('reset-page', this.resetPageHandler)
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
    window.removeEventListener('reverse-scale-updated', this.reverseScaleUpdatedHandler)
    window.removeEventListener('metric-range-updated', this.metricRangeUpdatedHandler)
  }

  initializeMap(){
    const mapData = JSON.parse(this.element.dataset.mapData)
    if (mapData === null) return
    
    this.cy = cytoscape({
      container: this.element,
      elements: JSON.parse(this.element.dataset.mapData)["elements"],
      wheelSensitivity: 0.05
    });

    // Define basic style
    this.cy.style([
      {
        selector: 'node',
        style: {
            'label': 'data(cluster_name)',
            'background-opacity': '0.8',
            'text-halign': 'center',
            'text-valign': 'center',
            'font-size': 0,
            'min-zoomed-font-size': 0,
            'font-family': 'urbanist',
            'transition-property': 'background-opacity',
            'transition-duration': '0.3s'
        }
      },
      {
        selector: 'node.border',
        style: {
            'border-width': '4%',
            'border-color': '#344054'
        }
      },
      {
        selector: 'node.outline',
        style: {
            'outline-width': '8%',
            'outline-color': '#D0D5DD',
            'outline-offset': '10%'
        }
      },
      {
        selector: 'node.opacity-100',
        style: { 'background-opacity': '1' }
      },
      {
        selector: 'node.opacity-50',
        style: { 'background-opacity': '0.5' }
      },
      {
        selector: 'node.opacity-20',
        style: { 'background-opacity': '0.2' }
      },
      {
        selector: 'edge',
        style: {
          'line-color': '#98A2B3',
          'width': 2,
          'opacity': 0.5,
          'transition-property': 'opacity',
          'transition-duration': '0.3s'
        }
      },
      {
          selector: 'edge.width',
          style: { 
            'width': 4,
            'opacity': 1
           }
      },
      {
        selector: 'edge.opacity-100',
        style:{ 'opacity': '1' }
      },
      {
          selector: 'edge.opacity-20',
          style:{ 'opacity': '0.2' }
      }
    ]);

    // Set the extremes to set then bubble size
    // NB: clusters that are minimized are excluded from the search of the extremes
    const metrics = this.cy.nodes().map(node => [node.data('metric'), node.data('minimized')])
    this.findExtremes(metrics, true)

    // Customize styling for the current graph
    this.cy.nodes().forEach((node) => {
      node.css('background-color', this.setColor(node));
      node.data('size', this.setNodeSize(node.data('metric'), node.data('validated'), node.data('minimized'), true));
      node.style({'width': node.data('size'), 'height': node.data('size')});
      node.css(this.fontStyle(node));
      if (!node.data('validated')) node.addClass('outline opacity-50');
    });

    // Display the graph
    this.displayMap();
  }

  // Set the layout of the graph and display it
  displayMap(){
    // Logic:
      // Step 1: display the map without filters to set the position of the nodes
      // Step 2: update the size of the node with the filters and adjust the position of nodes
      // Goal: keep a map as stable as possible

    // Define the layout COSE-BILKENT
    let layoutOptions = {
      name: "cose-bilkent",
      randomize: false,
      tile: false,
      padding: 0,
      nodeDimensionsIncludeLabels: false,
      idealEdgeLength: 30,
      nodeRepulsion: 100,
      nestingFactor: 1,
      edgeElasticity: 0.01,
      numIter: 2500,
      initialEnergyOnIncremental: 10,
      stop: () => {
        this.initialZoom = this.cy.zoom()*0.7;
        this.cy.minZoom(this.cy.zoom()*0.7);
        // Add interaction with the graph
        this.addMapListener();
      }
    }

    // Print the map without filters
    this.layout = this.cy.layout(layoutOptions);
    this.layout.run();

    // Define a one time event listener after the layout runs for the first time to adjust the node size based on filters
    // This will call the setNodesSizes method one time only for every load of the graph.
    this.layout.one('layoutstop', event => {
      this.unhighlightMacroTopic() // Set correct opacity of nodes if a macro_cluster is selected
      this.highlightedSelectedTopics() // Highligh topics after an action (merge, update name...)
      if (this.pageState().filters.length === 0 && !this.pageState().validated_only) return;

      this.filterMap() // Filtering of nodes is done through setNodesSizes. This method also filters by validated only if the flag is on.
    })
  }

  filterMap(updateMetricRange = true){
    if (this.pageState().scale !== 'micro') return

    this.setNodesSizes(updateMetricRange);
    setTimeout(()=> this.resizeMap(), 300);
  }

  resizeMap(){
    setTimeout(() => {
      this.cy.removeAllListeners()
      this.layout.run()
    }, 200)
  }

  aiModeNodeColor(precision){
    if (precision === null){
      return '#D0D5DD'
    } else if (precision >= 0.8){
      return '#26BD85'
    } else if (precision < 0.8 && precision >= 0.5){
      return '#F6CC76'
    } else if (precision < 0.5){
      return '#FF8066'
    }
  }

  // Set font style of node labels
  fontStyle(node){
    return {
      'font-size': node._private.style.width.value*0.15,
      'color': node.data('minimized') ? '#667085' : '#1D2939'
    }
  }

  // Find min and max of the impact
  findExtremes(array, updateMetricRange){
    // Determine bubble size range (does not take into account the minimized nodes)
    const bubbleSizeRange = array.filter(el => !el[1]).map(el => el[0])
    this.maxMetric = Math.max(...bubbleSizeRange)
    this.minMetric = Math.min(...bubbleSizeRange)
    if (!updateMetricRange) return

    // Determine metric range (take into account all the nodes)
    const metricRange = array.map(el => el[0])
    this.minMetricRange = Math.floor(Math.min(...metricRange))
    this.maxMetricRange = Math.ceil(Math.max(...metricRange))
    this.metricRange = [this.minMetricRange, this.maxMetricRange]
  }

  // Called by displayMap() when the graph is initialized
  // Triggered when a filter is applied or when the Validated Only flag is changed
  // Called by updateGraphAfterFilter from insights_update_front_controller once a filter is triggered
  // Called by setTrendMode() after the Validated Only flag is changed
  setNodesSizes(updateMetricRange = true){
    // Hide the no data window
    this.noDataPanelTarget.classList.add('hidden');

    let filteredNodes = this.pageState().filtered_nodes
    
    // Refresh extremes values (excludes minimized metrics from calculation)
    let nodes = Object.values(filteredNodes)
    const metrics = nodes.map(cluster => [cluster['metric'], cluster['minimized'] === 'true'])
    this.findExtremes(metrics, updateMetricRange)

    let noVisibleNodes = true;

    this.cy.nodes().forEach(node => {
      const clusterId = parseInt(node.id());
      const clusterValidated = node.data('validated');
      const clusterMetric = (filteredNodes[clusterId] === undefined) ? undefined : filteredNodes[clusterId]['metric'];
      const clusterMinimized = node.data('minimized')

      let size = this.setNodeSize(clusterMetric, clusterValidated, clusterMinimized, false);
      this.resizeNode(node, size);

      if (size !==0) noVisibleNodes = false;
    })

    // When there is no data returned, a window on top of the graph is displayed
    if (noVisibleNodes) this.noDataPanelTarget.classList.remove('hidden');
  }

  resizeNode(node, size=0) {
    node.animate({
        style: { width: size, height: size },
        duration: 300,
        easing: 'ease-in-sine'
    })

    setTimeout(() => {
      node.style(this.fontStyle(node));
    }, 450);
  }

  // Set the size of the bubbles based on a normalization
  setNodeSize(metric, clusterValidated, clusterMinimized, initMode){
    let maxBubbleSize = 500;
    let minBubbleSize = 50;

    let size;

    // Hide the bubbles by changing the size to 0 for those nodes that are either not validated for micro and Validated Only = on, either not part of the filter results
    if (!initMode && ((!clusterValidated && this.pageState().validated_only) || metric === undefined )){
      return 0;
    }

    // If the cluster metric is outside the defined metric range, set the size to 0
    if (!initMode && (metric < this.metricRange[0] || metric > this.metricRange[1])){
      return 0;
    }

    // If the cluster is minimized, set the size to the minimum
    if (clusterMinimized){
      return 50;
    }

    if (this.maxMetric === this.minMetric){
      // If min = max --> All nodes have the same impact, so arbitrary, we set a size to 100
      size = minBubbleSize;
    } else{
      if (this.pageState().reversed_scale){
        size = (this.maxMetric - metric)*(maxBubbleSize - minBubbleSize)/(this.maxMetric - this.minMetric) + minBubbleSize;
      } else {
        size = (metric - this.minMetric)*(maxBubbleSize - minBubbleSize)/(this.maxMetric - this.minMetric) + minBubbleSize;
      }
    }

    return size;
  }

  addMapListener(){
    this.cy.on('tap', event => {
      var selectedElement = event.target;

      // If we click on the background of the graph
      if( selectedElement === this.cy ){
        this.resetPage()
      // If we click on an object (node or edge)
      } else {
        if (selectedElement.group() === 'nodes'){
          const clusterId = selectedElement.data('cluster_id')
          if (event.originalEvent.ctrlKey){
            this.updateSelection(clusterId)
          } else {
            this.updateSelection(clusterId, true)
          }
          this.highlightedSelectedTopics()
          this.toggleRightPanel()
        } else if (selectedElement.group() === 'edges') {
          this.updateSelection(selectedElement.source().data('cluster_id'), true) // Add source node to selection
          this.updateSelection(selectedElement.target().data('cluster_id'))  // Add target node to selection
          this.highlightEdge(selectedElement);
        }
        this.updateMicroTable()
      }
    });
  }

  resetPage(){
    if (this.pageState().scale !== 'micro') return;
    
    this.resetSelection()
    this.resetMap()
    this.toggleRightPanel()
    this.updateMicroTable()
  }

  resetMap(){
    this.cy.nodes().forEach(node => {
      node.removeClass('opacity-20 border opacity-100')
      if (!node.data('validated')) node.addClass('opacity-50');
    })
    this.cy.edges().removeClass('opacity-100 opacity-20 width')
    this.updateNodesColor()
  }

  resetRightPanel(){
    const topicPanelEvent = new CustomEvent("topic-panel-updated");
    window.dispatchEvent(topicPanelEvent);
  }

  resetSelection(){
    const stateEvent = new CustomEvent("state-updated", { detail: { selected_ids: [], right_panel: null } });
    window.dispatchEvent(stateEvent);
  }

  updateSelection(clusterId, resetSelection = false){
    const oldSelection = (resetSelection) ? [] : this.pageState().selected_ids;

    // Selection
    let newSelection 
    if (oldSelection.includes(clusterId)){
      newSelection = oldSelection.filter(id => id !== clusterId)
    } else {
      newSelection = oldSelection.concat(clusterId)
    }

    // Right panel
    const rightPanel = (newSelection.length > 1) ? null : clusterId

    const stateEvent = new CustomEvent("state-updated", { detail: { selected_ids: newSelection, right_panel: rightPanel } });
    window.dispatchEvent(stateEvent);  
  }

  updateMicroTable(){
    const tableEvent = new CustomEvent("topic-clicked");
    window.dispatchEvent(tableEvent);
  }

  highlightedSelectedTopics(){
    const selection = this.pageState().selected_ids;

    // Depending on whether several nodes are selected
    if (selection.length === 0){
      this.resetMap()
    } else if (selection.length > 1){
      this.highlightMultipleTopic(selection)
    } else {
      this.highlightOneTopic(selection)
    }
  }

  highlightOneTopic(selection){
    this.cy.nodes().removeClass('border opacity-100 opacity-20 opacity-50')

    const selectedNode = this.cy.nodes(`[cluster_id=${selection[0]}]`)[0]
    selectedNode.removeClass('opacity-100 opacity-50 opacity-20')
    selectedNode.addClass('border opacity-100')

    // Any nodes other than the selected on and its neighbours become semi-transparent
    this.cy.elements()
        .difference(selectedNode.outgoers()
        .union(selectedNode.incomers()))
        .not(selectedNode)
        .addClass('opacity-20');

    // The node and its neighbours get highlighted
    selectedNode
        .outgoers()
        .union(selectedNode.incomers())
        .addClass('opacity-100');
  }

  highlightMultipleTopic(selection){
    this.cy.nodes().forEach(node => {
      node.removeClass('border opacity-100')
      if (!node.data('validated')) node.addClass('opacity-50');
    })

    const selectedNodes = this.cy.nodes().filter(node => selection.includes(node.data('cluster_id')));
    
    selectedNodes.removeClass('opacity-100 opacity-50 opacity-20 border')
    selectedNodes.addClass('opacity-100 border')
    this.cy.nodes().not(selectedNodes).addClass('opacity-20')

    this.updateNodesColor()
  }

  highlightEdge(edge) {
    const source = edge.source();
    const target = edge.target();

    this.cy.elements()
        .not(source).not(target).not(edge)
        .addClass('opacity-20');

    source.removeClass('opacity-50 opacity-20').addClass('opacity-100 border');
    target.removeClass('opacity-50 opacity-20').addClass('opacity-100 border');
    edge.addClass('opacity-100 width');

    this.updateNodesColor()
  }

  toggleRightPanel(){
    const topicPanelEvent = new CustomEvent("topic-panel-updated");
    window.dispatchEvent(topicPanelEvent);

    if (this.pageState().screen === 'small') return 

    // If the right panel is hidden because the graph size is 'big', then dispatch an event that will be captured by the left_panel_controller.js in order to decrease the graph size
    const toggleScreenEvent = new CustomEvent("toggle-screen-size");
    window.dispatchEvent(toggleScreenEvent);
  }

  toggleValidatedNodes(){
    if (this.pageState().scale !== 'micro') return

    this.setNodesSizes(false);
    if (this.pageState().validated_only) return;
    
    setTimeout(() => this.resizeMap(), 300)
  }

  reverseScale(isReversed){
    const stateEvent = new CustomEvent("state-updated", { detail: { reversed_scale: isReversed } });
    window.dispatchEvent(stateEvent);

    this.filterMap()
  }

  updateMetricRange(range){
    clearTimeout(this.metricTimeout)
    this.metricTimeout = setTimeout(() => {
      this.metricRange = range
      this.filterMap(false)
    }, 1000)
  }

  updateMapAfterResizing(){
    if (this.pageState().scale !== 'micro') return;
    setTimeout(() => this.resizeMap(), 500)
  }

  updateMapAfterModeChange(){
    this.updateNodesColor()
  }

  updateNodesColor(){
    this.cy.nodes().forEach((node) => {
      node.animate({
        style: { 'background-color': this.setColor(node) },
        duration: 300,
        easing: 'ease-in-sine'
      })
    });
  }

  rebuildMap(data){
    if (this.pageState().scale !== 'micro' || this.pageState().display !== 'map') {
      this.element.classList.add('hidden')
    }

    if (this.pageState().scale !== 'micro') return;

    if (!Object.keys(data).includes('Topics::MicroMapComponent')) return;

    if (this.cy) this.cy.destroy()
    this.element.insertAdjacentHTML('afterend', data['Topics::MicroMapComponent'])
    this.element.remove();
  }

  setColor(node){
    const clusterId = node.data('cluster_id')

    // If the mode 'multiple selection' is activated, bubble become gray or blue if selected
    if (this.pageState().selected_ids.length > 1){
      return (this.pageState().selected_ids.includes(clusterId)) ? '#98A2B3' : '#D0D5DD'
    } else {
      // AI Mode
      if (this.pageState().mode === 'ai') return this.aiModeNodeColor(node.data('precision'))
      // AiAgent Mode
      if (this.pageState().mode === 'ai_agents') {
        if (this.pageState().selected_ai_agent){
          const clusterIds = this.pageState().selected_ai_agent.cluster_ids
          return (clusterIds.includes(node.data('cluster_id'))) ? '#F6CC76' : '#D0D5DD'
        } else {
          return '#D0D5DD'
        }
      }
      // Topics Mode
      if (this.pageState().selected_tag){
        const clusterIds = this.pageState().selected_tag.cluster_ids
        return (clusterIds.includes(node.data('cluster_id'))) ? this.pageState().selected_tag.color : '#D0D5DD'
      }
      if (this.pageState().selected_knowledge){
        const clusterIds = this.pageState().selected_knowledge.cluster_ids
        return (clusterIds.includes(node.data('cluster_id'))) ? '#F6CC76' : '#D0D5DD'
      }
      // Default: return macro cluster color
      return node.data('macro_cluster_color') || '#D0D5DD'
    }
  }

  highlightMacroTopic(macroClusterId){
    // If select mode is selected or if a tag is selected --> no action
    if (this.pageState().selected_ids.length > 1) return;

    const macroClusterNodes = this.cy.nodes().filter(node => node.data('macro_cluster_id') === parseInt(macroClusterId, 10));

    this.cy.nodes().forEach(node => {
      node.stop().animate({
        style: { 'background-color': node.data('macro_cluster_color') || '#D0D5DD' },
        duration: 300,
        easing: 'ease-in-sine'
      })
      node.removeClass('border opacity-100 opacity-50 opacity-20')

      if (macroClusterNodes.includes(node)) {
        return node.addClass('opacity-100')
      } else {
        return node.addClass('opacity-20');
      }
    })

    this.cy.edges().forEach(edge => edge.addClass('opacity-20'))
  }

  unhighlightMacroTopic(){
    // If select mode is selected or if a tag is selected --> no action
    if (this.pageState().selected_ids.length > 1) return;

    if (this.pageState().selected_macro_cluster_id !== null){
      this.highlightMacroTopic(this.pageState().selected_macro_cluster_id)
    } else {
      this.cy.nodes().forEach(node => {
        node.stop().animate({
          style: { 'background-color': this.setColor(node) },
          duration: 300,
          easing: 'ease-in-sine'
        })
        node.removeClass('opacity-100 opacity-20')
        if (!node.data('validated')) node.addClass('opacity-50');
      })
  
      this.cy.edges().forEach(edge => edge.removeClass('opacity-20'))
    }
  }

  highlightTag(color, clusterIds){
    // If select mode is selected or if a tag is selected --> no action
    if (this.pageState().selected_ids.length > 1) return;

    this.cy.nodes().forEach(node => {
      if (clusterIds.includes(node.data('cluster_id'))){
        node.stop().animate({
          style: { 'background-color': color },
          duration: 300,
          easing: 'ease-in-sine'
        })
      } else {
        node.stop().animate({
          style: { 'background-color': '#D0D5DD' },
          duration: 300,
          easing: 'ease-in-sine'
        })
      }
    })    
  }

  unhighlightTag(){
    // If select mode is selected or if a tag is selected --> no action
    if (this.pageState().selected_ids.length > 1) return;

    const selectTag = this.pageState().selected_tag

    if (selectTag){
      this.highlightTag(selectTag.color, selectTag.cluster_ids)
    } else {
      this.cy.nodes().forEach(node => {
        node.stop().animate({
          style: { 'background-color': this.setColor(node) },
          duration: 300,
          easing: 'ease-in-sine'
        })
      })
    }
  }

  highlightKnowledge(clusterIds){
    // If select mode is selected or if a tag is selected --> no action
    if (this.pageState().selected_ids.length > 1) return;

    this.cy.nodes().forEach(node => {
      if (clusterIds.includes(node.data('cluster_id'))){
        node.stop().animate({
          style: { 'background-color': '#F6CC76' },
          duration: 300,
          easing: 'ease-in-sine'
        })
      } else {
        node.stop().animate({
          style: { 'background-color': '#D0D5DD' },
          duration: 300,
          easing: 'ease-in-sine'
        })
      }
    }) 
  }

  unhighlightKnowledge(){
    // If select mode is selected or if a tag is selected --> no action
    if (this.pageState().selected_ids.length > 1) return;

    const selectKnowledge = this.pageState().selected_knowledge

    if (selectKnowledge){
      this.highlightKnowledge(selectKnowledge.cluster_ids)
    } else {
      this.cy.nodes().forEach(node => {
        node.stop().animate({
          style: { 'background-color': this.setColor(node) },
          duration: 300,
          easing: 'ease-in-sine'
        })
      })
    }
  }

  highlightAiAgent(clusterIds){
    // If select mode is selected or if a tag is selected --> no action
    if (this.pageState().selected_ids.length > 1) return;

    this.cy.nodes().forEach(node => {
      if (clusterIds.includes(node.data('cluster_id'))){
        node.stop().animate({
          style: { 'background-color': '#F6CC76' },
          duration: 300,
          easing: 'ease-in-sine'
        })
      } else {
        node.stop().animate({
          style: { 'background-color': '#D0D5DD' },
          duration: 300,
          easing: 'ease-in-sine'
        })
      }
    }) 
  }

  unhighlightAiAgent(){
    // If select mode is selected or if a tag is selected --> no action
    if (this.pageState().selected_ids.length > 1) return;

    const selectAiAgent = this.pageState().selected_ai_agent

    if (selectAiAgent){
      this.highlightAiAgent(selectAiAgent.cluster_ids)
    } else {
      this.cy.nodes().forEach(node => {
        node.stop().animate({
          style: { 'background-color': this.setColor(node) },
          duration: 300,
          easing: 'ease-in-sine'
        })
      })
    }
  }

  zoomIn(){
    if (this.pageState().scale !== 'micro') return;

    const currentZoom = this.cy.zoom();
    this.cy.zoom(currentZoom + 0.05);
  }

  zoomOut(){
    if (this.pageState().scale !== 'micro') return;
    
    const currentZoom = this.cy.zoom();
    this.cy.zoom(currentZoom - 0.05);
  }
}
