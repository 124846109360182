import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['btn']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  toggleFilter(){
    const previouslySelectedBtn = this.element.querySelector('.display-btn-selected')

    event.currentTarget.classList.replace('display-btn', 'display-btn-selected')

    if (previouslySelectedBtn) {
      previouslySelectedBtn.classList.replace('display-btn-selected', 'display-btn')
    }

    this.addFilterToFilterBar();
  }

  addFilterToFilterBar(){
    const selectedBtn = this.element.querySelector('.display-btn-selected')

    // Remove former filter
    const filterBar = document.querySelector('[data-controller="filter-bar"]')
    let filters = JSON.parse(filterBar.dataset.filters)
    filters = filters.filter(el => el['table_id'] !== 'conversations.created_at')

    if (selectedBtn) {
      // Save the new filters
      filters.push(JSON.parse(selectedBtn.dataset.filter))
    }

    filterBar.dataset.filters = JSON.stringify(filters)

    const filterEvent = new CustomEvent("filters-updated", { detail: { filters: filters } });
    window.dispatchEvent(filterEvent);
  }
}
