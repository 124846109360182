import consumer from "./consumer"


document.addEventListener('turbolinks:load', () => {

  const cobbaiChat = document.querySelector('#cobbai-chat');
  if (!cobbaiChat) return;

  const chatState = JSON.parse(cobbaiChat.dataset.chatState)
  const chatId = chatState.chat_id
  const userId = chatState.user_id
  const roomId = `${chatId}_${userId}`

  // Check if the user is already subscribed to the channel
  const existingSubscription = consumer.subscriptions.subscriptions.find(sub => {
    return sub.identifier === JSON.stringify({ channel: "ChatChannel", room_id: roomId });
  });
  if (existingSubscription) return;

  consumer.subscriptions.create({ channel: "ChatChannel", room_id: roomId }, {
    connected() {
      // Called when the subscription is ready for use on the server
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
  
    received(data) {
      // Called when there's incoming data on the websocket for this channel
      if (data.cableReady) CableReady.perform(data.operations)
    }
  });
})