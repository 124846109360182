import { Controller } from "@hotwired/stimulus"
import initializeTooltips from '../../../components/app_tooltips';
import { projectName } from '../../../components/utilities';


export default class extends Controller {
  
  connect(){
    initializeTooltips(this.element)
  }

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]')?.dataset?.pageState || '{}')
  }

  closePanel(){
    this.element.classList.add('hidden')

    // Reset page
    const resetEvent = new CustomEvent("reset-page");
    window.dispatchEvent(resetEvent);
  }

  updateTitle(){
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/articles/${this.element.dataset.id}/update_title`
    const body = {
      title: event.currentTarget.value
    }

    fetch(url, {
        method: 'PATCH',
        headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
      const listRow = data['list_row']

      if (listRow) {
        // Find row
        const row = document.querySelector(`#${listRow['row_id']}`)

        // Add new row under the one (if present)
        if (listRow['list_row']) row.insertAdjacentHTML('afterend', listRow['list_row'])
        
        // Delete former row
        row.remove();

        // Highlight the new row
        const new_row = document.querySelector(`#${listRow['row_id']}`)
        new_row.classList.add('bg-gray-200')
      }    
    });
  }

  updateDescription(){
    const articleDescription = document.querySelector('#article-description-edit [data-rich-text-target="hiddenField"]').value
    // Check if there are any changes in the description, if not exit the logic
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/articles/${this.element.dataset.id}/update_description`
    const body = {
      description: articleDescription
    }

    fetch(url, {
        method: 'PATCH',
        headers: {
            Accept: "application/js",
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);   
    });

  }

  deleteArticle() {
    const message = event.currentTarget.dataset.confirmMessage
    
    if (!confirm(message)) {
      event.preventDefault()
      return
    }

    // your existing delete logic here (e.g., fetch or Ajax call)
    this.sendDeleteRequest()
  }

  sendDeleteRequest(){
    // Show loader
    event.currentTarget.querySelector('[data-icon]').classList.add('hidden')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/articles/${this.element.dataset.id}`
    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      const deletedRow = data['deleted_row_id']

      if (deletedRow) {
        // Find row and delete it
        document.querySelector(`#${deletedRow}`).remove();

        // Update the count 
        const countDiv = document.querySelector('#results-count')
        const countText = countDiv.innerText
        const updatedText = countText.replace(/\d+/, (match) => Number(match) - 1);
        countDiv.innerText = updatedText

        // Update page state
        const stateEvent = new CustomEvent("state-updated", { detail: data['page_state'] });
        window.dispatchEvent(stateEvent);

        // Hide Right panel
        const articlePanelEvent = new CustomEvent("article-panel-updated");
        window.dispatchEvent(articlePanelEvent);
      }    

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    });
  }
}
