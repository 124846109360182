import { Controller } from "@hotwired/stimulus"
import setTooltips from '../components/app_tooltips';

export default class extends Controller {
  static targets = ['guidelineWrapper', 'guidelineTpl', 'submitBtn',
                    'text', 'agentRoleInput', 'guidelineInput',
                    'answerWrapper', 'guidelines', 'qnis', 'question', 'answer', 'conversationWrapper', 'conversationLink', 'errorMsg', 'loader']

  connect(){
    setTooltips(this.element)
  }

  addGuideline(){
    const position = parseInt(this.element.dataset.guidelineCount, 10) + 1

    const newGuideline = this.guidelineTplTarget.innerHTML
                             .replace(/POSITION/g, position)

    this.guidelineWrapperTarget.insertAdjacentHTML('beforeend', newGuideline)
    setTooltips(this.element)

    this.element.dataset.guidelineCount = position

    if (this.hasSubmitBtnTarget) this.submitBtnTarget.classList.remove('hidden')
  }

  removeGuideline(){
    event.currentTarget.closest('[data-guideline]').remove()

    const automationUpdatedEvent = new CustomEvent("automation-updated");
    window.dispatchEvent(automationUpdatedEvent);
  }

  showFlashMessage(){
    const [_data, _status, xhr] = event.detail;
    const data = JSON.parse(xhr.response)

    document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
  }

  testGuidelines(){
    this.loaderTarget.classList.remove('hidden')
    this.answerWrapperTarget.classList.add('hidden')
    this.errorMsgTarget.classList.add('hidden')

    const url = this.element.dataset.testPath;
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    const agentRole = (this.hasAgentRoleInputTarget) ? this.agentRoleInputTarget.value : null
    const guidelines = this.guidelineInputTargets.map(input => input.value)

    const body = {
      text: this.textTarget.value,
      agent_role: agentRole,
      guidelines: guidelines
    }

    fetch(url, {
      method: 'post',
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin",
      body: JSON.stringify(body)
    })
  .then(response => response.json())
  .then(data => {
    this.loaderTarget.classList.add('hidden')

    if (data['error']){
      this.errorMsgTarget.innerHTML = data.error
      this.answerWrapperTarget.classList.add('hidden')
      this.errorMsgTarget.classList.remove('hidden')
    } else {
      this.qnisTarget.innerHTML = this.list(data.used_qnis);
      this.guidelinesTarget.innerHTML = this.list(data.guidelines);
      this.questionTarget.innerHTML = data.question;
      this.answerTarget.innerHTML = data.answer;
      if (data.conversation_path){
        this.conversationWrapperTarget.classList.remove('hidden')
        this.conversationLinkTarget.href = data.conversation_path
      } else {
        this.conversationWrapperTarget.classList.add('hidden')
      }
      
      this.answerWrapperTarget.classList.remove('hidden')
      this.errorMsgTarget.classList.add('hidden')
    }
  })
  }

  list(elements){
    let htmlList = "<ul>";
    elements.forEach(element => {
      htmlList += `<li>${element}</li>`
    })
    htmlList += "</ul>"

    return htmlList
  }
}