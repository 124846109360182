import { Controller } from "@hotwired/stimulus"
import { projectName } from '../components/utilities';
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
	static targets = ['chevron', 'panel', 'select'];

	// Event handlers
  articleCardUpdateHandler = (event) => this.updateArticleCard(event.detail.articleId, event.detail.component)

	connect(){
		// Event Listeners
		window.addEventListener('article-card-updated', this.articleCardUpdateHandler)

		initializeSelect({
		  selector: this.selectTarget,
		  otherSettings: {
		    searchField: ['cluster', 'macro_cluster'],
		    options: JSON.parse(this.selectTarget.dataset.options),
		    items: this.selectTarget.dataset.items,
		    render: {
		      option: function(data, escape) {
		      	if (data.cluster === null){
		      		return `<div class="cursor-pointer flex justify-start items-center gap-2">
		      		          <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full" style="background-color:${data.color}"></div>
		      		          <span style="color:${data.color}">${data.macro_cluster}</span>
		      		        </div>`
		      	} else {
		      		const macro_cluster = (data.macro_cluster === null) ? '' : `${escape(data.macro_cluster)}`

		      		return `<div class="cursor-pointer flex justify-start items-center gap-2">
		      		          <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full ${(macro_cluster === '') ? 'hidden' : ''}" style="background-color:${macro_cluster === '' ? '' : data.color}"></div>
		      		          <span class="text-gray-500 ${(macro_cluster === '') ? 'hidden' : ''}" style="color:${macro_cluster === '' ? '' : data.color}">${macro_cluster}</span>
		      		          <span class="text-gray-500 ${(macro_cluster === '') ? 'hidden' : ''}">-</span>
		      		          <span class="">${escape(data.cluster)}</span>
		      		          ${(data.state === 'validated') ? '<i class="fa-light fa-circle-check text-green-400"></i>' : ''}
		      		        </div>`
		      	}
		      },
		      item: function(data, escape) {
		      	if (data.cluster === null){
		      		return `<div class="cursor-pointer flex justify-start items-center gap-2">
		      		          <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full" style="background-color:${data.color}"></div>
		      		          <span class="text-gray-500">${data.macro_cluster}</span>
		      		        </div>`
		      	} else {
		      		const macro_cluster = (data.macro_cluster === null) ? '' : `${escape(data.macro_cluster)}`

		      		return `<div class="cursor-pointer flex justify-start items-center gap-2">
		      		          <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full ${(macro_cluster === '') ? 'hidden' : ''}" style="background-color:${macro_cluster === '' ? '' : data.color}"></div>
		      		          <span class="text-gray-500 ${(macro_cluster === '') ? 'hidden' : ''}" style="color:${macro_cluster === '' ? '' : data.color}">${macro_cluster}</span>
		      		          <span class="text-gray-500 ${(macro_cluster === '') ? 'hidden' : ''}">-</span>
		      		          <span class="">${escape(data.cluster)}</span>
		      		        </div>`
		      	}
		      }
		    },
				onChange: (value) =>{
					if (value === ''){
						const articleId = this.element.dataset.articleId
						const value = JSON.stringify({ type: null, article_id: articleId, element_id: null })
						this.updateArticle(articleId, value)
					} else {
						const articleId = this.element.dataset.articleId
						this.updateArticle(articleId, value)
					}
				}
		  }
		});
	}

	// Remove Event listeners if the controller is removed
	disconnect(){
		window.removeEventListener('article-card-updated', this.articleCardUpdateHandler)
	}

	togglePanel(){
	  /*Note on the accordions: for the animation to work, we need to set a max-width before and after the transition
	  However, we need to remove the max-width at the end of the expension phase because otherwise, 
	  it will come into conflict with the increase of the panel. For instance, it will hide the new lines of the filters.
	  We also need to remove the overflow hidden. Otherwise the dropdowns of the select will be hidden*/

	  if (this.panelTarget.style.maxHeight) {
	    this.chevronTarget.classList.remove('fa-rotate-90')
	    this.panelTarget.style.maxHeight = null;
	  } else {
	    this.chevronTarget.classList.add('fa-rotate-90')
	    this.panelTarget.style.maxHeight = this.panelTarget.scrollHeight + "px"
	  } 
	}

	openModal(){
		const modalId = event.currentTarget.dataset.id;
		MicroModal.show(modalId);
	}

	updateArticle(articleId, value){
	  const url = `${window.location.origin}/${projectName(window.location.pathname)}/articles/${articleId}`;
	  const body = {
	    value: value,
	    origin: 'knowledge_show'
	  }

	  const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
	      .content.value;

	  fetch(url, {
	      method: "PATCH",
	      headers: {
	          Accept: "application/js",
	          "Content-Type": "application/json",
	          "X-CSRF-Token": csrfToken
	      },
	      credentials: "same-origin",
	      body: JSON.stringify(body)
	  })
	  .then(response => response.json())
	  .then(data => {
	    document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
	  });
	}

	updateArticleCard(articleId, newArticle){
		if (articleId !== parseInt(this.element.dataset.articleId, 10)) return;

		const tempDiv = document.createElement("div");
		tempDiv.innerHTML = newArticle.trim();
		const newArticleElement = tempDiv.querySelector("[data-controller='article']");

		this.element.replaceWith(newArticleElement);
	}

	updateUsedByAiAgentsSwitch(){
		const url = event.currentTarget.dataset.route;

		// AJAX call to update the flag
		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
				.content.value;
		fetch(url, {
				method: 'PATCH',
				headers: {
					Accept: "application/js",
					"Content-Type": "application/json",
					"X-CSRF-Token": csrfToken
				},
				credentials: "same-origin",
				body: '{}'
			})
		.then(response => response.json())
		.then(data => {
			document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
		})
	}
	
}
