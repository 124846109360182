import { Controller } from "@hotwired/stimulus"
import { projectName } from "../../components/utilities"

export default class extends Controller {
  static targets = ['button', 'wrapper', 'panel', 'chevron']

  showFilterPanel(){
    this.buttonTarget.classList.add('hidden')
    this.wrapperTarget.classList.remove('hidden')
    this.togglePanel()
  }

  removeFilters(){
    const destroyConfirmation = confirm(event.currentTarget.dataset.confirm)
    if (!destroyConfirmation) return;

    const aiAgentId = this.element.dataset.id

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/ai_agents/${aiAgentId}/update_filters`
    const body = { data: { filters: [] } }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      if (data.updated){
        this.buttonTarget.classList.remove('hidden')
        this.wrapperTarget.classList.add('hidden')
      }

      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    });
  }

  togglePanel(){
    /*Note on the accordions: for the animation to work, we need to set a max-width before and after the transition
    However, we need to remove the max-width at the end of the expansion phase because otherwise, 
    it will come into conflict with the increase of the panel. For instance, it will hide the new lines of the filters.
    We also need to remove the overflow hidden. Otherwise the dropdowns of the select will be hidden*/

    if (this.panelTarget.style.maxHeight) {
      this.chevronTarget.classList.remove('fa-rotate-180')
      this.panelTarget.style.maxHeight = this.panelTarget.scrollHeight + "px";
      setTimeout(() => {
        this.panelTarget.style.maxHeight = null;
        this.panelTarget.style.overflow = 'hidden'
      }, 10)
    } else {
      this.chevronTarget.classList.add('fa-rotate-180')
      this.panelTarget.style.maxHeight = this.panelTarget.scrollHeight + "px";
      setTimeout(() => {
        this.panelTarget.style.overflow = 'visible'
        this.panelTarget.style.maxHeight = 'fit-content'
      }, 250)
    }
  }
}