import { Controller } from "@hotwired/stimulus"
import { projectName } from "../../components/utilities"

export default class extends Controller {
  static targets = ['input', 'closeBtn', 'list', 'tagBtn', 'automationBtn']

  search(){
    if (event.currentTarget.value === '' || event.currentTarget.value === undefined){
      this.closeBtnTarget.classList.add('hidden')
    } else {
      this.closeBtnTarget.classList.remove('hidden')
    }

    if (!(event.key === 'Enter' || event.type === "click")) return

    // Show the loader
    this.showLoader()

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/automations/search_card`
    const body = { term: this.inputTarget.value, tag: this.element.dataset.tag }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.listTarget.innerHTML = data.filtered_list
      this.hideLoader()
    });
  }

  searchTag(){
    // If already clicked, we remove the tag from the search
    if (event.currentTarget.classList.contains('display-btn-selected')){
      event.currentTarget.classList.remove('display-btn-selected')
      event.currentTarget.classList.add('display-btn')
      this.element.dataset.tag = ''
      this.search()
      return;
    }

    this.tagBtnTargets.forEach(btn => {
      btn.classList.remove('display-btn-selected')
      btn.classList.add('display-btn')
    })

    event.currentTarget.classList.add('display-btn-selected')
    event.currentTarget.classList.remove('display-btn')

    this.element.dataset.tag = event.currentTarget.dataset.tag

    this.search()
  }

  showLoader(){
    this.element.querySelectorAll('[data-icon]').forEach(el => el.classList.add('hidden'))
    this.element.querySelector('[data-loading]').classList.remove('hidden')
  }

  hideLoader(){
    this.element.querySelectorAll('[data-loading]').forEach(el => el.classList.add('hidden'))
    this.element.querySelectorAll('[data-icon]').forEach(el => el.classList.remove('hidden'))
  }

  addAutomation(){
    if (event.currentTarget.dataset.disabled === 'true') return;

    this.automationBtnTargets.forEach(btn => {
      btn.classList.add('btn-disabled')
    })

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/automations`
    const body = { 
      ai_agent_id: this.element.dataset.aiAgentId,
      position: this.element.dataset.position, 
      automation_template_id: event.currentTarget.dataset.automationTemplateId,
      export_id: event.currentTarget.dataset.exportId
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Update ai_agent
      const aiAgentEvent = new CustomEvent("automation-added", { detail: data });
      window.dispatchEvent(aiAgentEvent);

      this.automationBtnTargets.forEach(btn => {
        btn.classList.remove('btn-disabled')
      })
    }); 
  } 
}

