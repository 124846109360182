import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["chatWindow", "btnIconShowChat", "btnIconHideChat"]
  
  connect() {
    this.chatWindowState = 'closed'
  }

  toggleChatWindow(){
    this.chatWindowTarget.classList.toggle("hidden");
    this.btnIconShowChatTarget.classList.toggle("hidden");
    this.btnIconHideChatTarget.classList.toggle("hidden");   

    if (this.chatWindowState === 'closed'){
      this.chatWindowState = 'open'
      window.parent.postMessage({ action: "expandChat" }, "*");
    } else {
      this.chatWindowState = 'closed'
      window.parent.postMessage({ action: "minimizeChat" }, "*");
    }      
    
    const openChatEvent = new CustomEvent("open-chat-window");
    window.dispatchEvent(openChatEvent);
  }
}
