import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	updateUsedByAiAgentsSwitch(){
		const url = event.currentTarget.dataset.route;

		// AJAX call to update the flag
		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
				.content.value;
		fetch(url, {
				method: 'PATCH',
				headers: {
					Accept: "application/js",
					"Content-Type": "application/json",
					"X-CSRF-Token": csrfToken
				},
				credentials: "same-origin",
				body: '{}'
			})
		.then(response => response.json())
		.then(data => {
			document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
		})
	}
}
