import { Controller } from "@hotwired/stimulus"
import MicroModal from "micromodal";
import { projectName } from '../../components/utilities';
import { openModal, checkConsistency } from "../../components/modal";
import initializeSelect from "../../components/initialize_select";

export default class extends Controller {
  static targets = ["conversationsList", "conversationsModal", "selectedConversations",
                    "createCluster", "deleteConversationsModal", "addToClusterModal", "addToClusterSelect", 
                    "removeFromClusterModal", "ignoreIdeasModal", "updateSentimentModal",
                    "conversationFull", "chevron"];
  
  // Event handlers
  filterDataUpdateHandler = (event) => this.updateList(event.detail.data)

  connect(){
    window.addEventListener('filtered-data-updated', this.filterDataUpdateHandler)
  }

  disconnect(){
    window.removeEventListener('filtered-data-updated', this.filterDataUpdateHandler)
  }

  updateList(data){
    if (data['url']){
      window.location.href = data['url']
      return;
    }

    if (data['refreshed_list']){
      const list = document.querySelector('#conversations_index_conversations_list');
      list.insertAdjacentHTML('beforebegin', data['refreshed_list'])
      list.remove();
    }

    if (data['flash']){
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    }
  }

  securityCheck(){
    const input = this.activeModal.querySelector('#security_check');
    const submit = this.activeModal.querySelector('#submit');

    checkConsistency(input, submit);
  }

  openModal(id) {
    MicroModal.show(id);
  }

  // DELETE CONVERSATIONS______________________________________________________________________________________________________________

  // Open the modal that ask for the change
  deleteConversationsModal(){
    this.activeModal = this.deleteConversationsModalTarget;
    openModal(this.activeModal, 10, event.currentTarget);
  }

  // Send the request to delete conversations
  deleteConversations(){
    const allowedAction = event.currentTarget.dataset.allowed === "true";
    if (!allowedAction) return;

    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/conversations/delete_many`;
    const verb = "DELETE";
    const body = JSON.parse(this.activeModal.dataset.requestData);

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  // ADD IDEA TO CLUSTER____________________________________________________________________________________________________

  // Open the modal that ask for the change
	addToClusterModal(){
    this.closeDropdown()

    const tomInstance = this.addToClusterSelectTarget.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();

    // Initialize clusters selection for add_to_cluster modal
    initializeSelect({
      selector: this.addToClusterSelectTarget,
      config: JSON.parse(this.addToClusterSelectTarget.dataset.config),
      otherSettings: {
        searchField: ['cluster', 'macro_cluster'],
        render: {
          option: function(data, escape) {
            const macro_cluster = (data.macro_cluster === null) ? '' : `${escape(data.macro_cluster)}`

            return `<div class="cursor-pointer flex justify-start items-center gap-2">
                      <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full ${(macro_cluster === '') ? 'hidden' : ''}" style="background-color:${macro_cluster === '' ? '' : data.color}"></div>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}" style="color:${macro_cluster === '' ? '' : data.color}">${macro_cluster}</span>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}">-</span>
                      <span>${escape(data.cluster)}</span>
                      ${(data.state === 'validated') ? '<i class="fa-light fa-circle-check text-sm text-green-500"></i>' : ''}
                    </div>`
          },
          item: function(data, escape) {
            const macro_cluster = (data.macro_cluster === null) ? '' : `${escape(data.macro_cluster)}`

            return `<div class="cursor-pointer flex justify-start items-center gap-2">
                      <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full ${(macro_cluster === '') ? 'hidden' : ''}" style="background-color:${macro_cluster === '' ? '' : data.color}"></div>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}" style="color:${macro_cluster === '' ? '' : data.color}">${macro_cluster}</span>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}">-</span>
                      <span>${escape(data.cluster)}</span>
                    </div>`
          }
        }
      }
    });

		this.activeModal = this.addToClusterModalTarget;
  	openModal(this.activeModal, 10, event.currentTarget);
	}

	// Send the request to add selected ideas to the cluster
	addToCluster(){
		const allowedAction = event.currentTarget.dataset.allowed === "true";
		if (!allowedAction) return;

		const cluster_name = this.addToClusterSelectTarget.value;

		if (cluster_name === ""){
			// Show error message
			this.activeModal.querySelector("[data-flash]").classList.remove('hidden')
      return;
    }

    // Hide error message
    this.activeModal.querySelector("[data-flash]").classList.add('hidden')

    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/ideas/add_cluster`;
    const verb = "POST";
		const body = {
      ...JSON.parse(this.activeModal.dataset.requestData),
      cluster_name: cluster_name
    };      

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
	}

  // REMOVE IDEAS FROM A SPECIFIC CLUSTER____________________________________________________________________________

	// Open the modal that ask for the change
	removeFromClusterModal(){
    this.closeDropdown()

		this.activeModal = this.removeFromClusterModalTarget;
		openModal(this.activeModal, 10, event.currentTarget);
	}

	// Send the request to change the cluster of selected ideas
	removeCluster(){
		const allowedAction = event.currentTarget.dataset.allowed === "true";
		if (!allowedAction) return;

		// Launch request
		const url = `${window.location.origin}/${projectName(window.location.pathname)}/ideas/remove_cluster`;
		const verb = "POST";
    const body = JSON.parse(this.activeModal.dataset.requestData);

		this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
	}

  // IGNORE IDEAS____________________________________________________________________________________________________

  // Open the modal that ask for the change
  ignoreIdeasModal(){
    this.closeDropdown()

    this.activeModal = this.ignoreIdeasModalTarget;

    openModal(this.activeModal, 10, event.currentTarget);
  }

  // Send the request to ignore ideas
  ignoreIdeas(){
    const allowedAction = event.currentTarget.dataset.allowed === "true";
    if (!allowedAction) return;

    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/ideas/ignore_single_conversation_ideas`;
    const verb = "POST";
    const body = JSON.parse(this.activeModal.dataset.requestData)

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  // UPDATE SENTIMENT________________________________________________________________________________________________

  // Open the modal that ask for the change
  updateSentimentModal(){
    this.closeDropdown()
    
    this.activeModal = this.updateSentimentModalTarget;

    this.resetEmoji()

    openModal(this.activeModal, 10, event.currentTarget);
  }

  resetEmoji(){
    const emojiIcons = this.activeModal.querySelectorAll('[data-sentiment]');
    emojiIcons.forEach( icon => {
      icon.classList.remove('font-extrabold', 'opacity-85', 'opacity-25');
      icon.removeAttribute('selected');
    })
  }

  // Send the request to update sentiment for the selected ideas
  updateSentiment(){
    const allowedAction = event.currentTarget.dataset.allowed === "true";
    if (!allowedAction) return;

    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/ideas/update_sentiment_single_conversation_ideas`;
    const verb = "POST";
    const body = {
      ...JSON.parse(this.activeModal.dataset.requestData),
      sentiment: this.sentiment
    };

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget;
    button.classList.add('btn-disabled');
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  toggleSentiment(){
    // First, gray-out all the emoji icons
    const emojiContainer = event.currentTarget.parentElement;
    const emojiElements = Array.from(emojiContainer.children);
    emojiElements.forEach(element => this.grayoutUnselected(element));

    // Second, highlight only the selected emoji
    this.highlightSelected();
  }

  highlightSelected(){
    this.sentiment = event.currentTarget.dataset.sentiment;
    event.currentTarget.classList.add('font-extrabold','opacity-85');
    event.currentTarget.setAttribute('selected','true');
    event.currentTarget.classList.remove('opacity-25');
  }

  grayoutUnselected(element){
    element.classList.add('opacity-25');
    element.removeAttribute('selected');
    element.classList.remove('font-extrabold','opacity-85');
  }

  // AJAX CALL TO UPDATE CLUSTERS_______________________________________________________________________________________

	// AJAX call to update cluster or ideas
	AJAXcall(url, verb, body){
		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
		    .content.value;
		fetch(url, {
		    method: verb,
		    headers: {
		      Accept: "application/js",
		      "Content-Type": "application/json",
		      "X-CSRF-Token": csrfToken
		    },
		    credentials: "same-origin",
		    body: JSON.stringify(body)
		  })
		.then(response => response.json())
		.then(data => {
      window.location.href = data["url"];
		});
	}

  closeDropdown(){
    // Remove any dropdowns if loaded on the page before opening the modal
    const dropdown = document.querySelector('[data-tippy-root]');
    if (dropdown) dropdown.remove();
  }
}
