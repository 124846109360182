// Entry point for the build script in your package.json
import "./controllers"
import "./channels"

require("@rails/ujs").start();


import Turbolinks from "turbolinks"
Turbolinks.start()

//icons
// import "@fortawesome/fontawesome-pro/css/fontawesome.css"
// import "@fortawesome/fontawesome-pro/css/light.css"
import "@awesome.me/kit-99c405ec13/icons/css/all.css"

// NB: to import a css file from node modules, just specify the folder library (without node_modules prefix)

// Import js components
import initializeTooltips from './components/app_tooltips';
import initializeFlatpickr from "./components/flatpickr_date";
import { initializeModals } from "./components/modal";
import initParticles from "./components/particles_bg";

document.addEventListener("turbolinks:load", () => {

  // Initialize tooltips
  initializeTooltips(document)

  // Initialize Flatpickr date
  const dateFields = document.querySelectorAll('input[type="date"]');
  dateFields.forEach(dateField => initializeFlatpickr(dateField))
  const datetimeFields = document.querySelectorAll('input[type="datetime-local"]');
  datetimeFields.forEach(datetimeField => initializeFlatpickr(datetimeField, { enableTime: true, dateFormat: "Y-m-d H:i"}))

  // Initialize modals
  initializeModals();

  // Initialize particles background
  initParticles();

  // Remove service worker programmatically
  if(window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
     for(let registration of registrations) {
      registration.unregister()
    } })
  }
});

const faviconTag = document.getElementById("faviconTag");
const isDark = window.matchMedia("(prefers-color-scheme: dark)");
const changeFavicon = () => {
  if (faviconTag) {
    if (isDark.matches) faviconTag.href = faviconTag.dataset.dark;
    else faviconTag.href = faviconTag.dataset.light;
  }
};
changeFavicon()