import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../components/utilities';

export default class extends Controller {

  static targets = ["previewPanel"]

  // Event handlers
  updateChatPreviewHandler = (event) => this.updatePreview(event.detail.updatedField);

  connect(){
    window.addEventListener('update-chat-preview', this.updateChatPreviewHandler)
  }

  disconnect(){
    window.removeEventListener('update-chat-preview', this.updateChatPreviewHandler)
  }

  handleInputChange(){
    // Identify the updated field
    const field = event.currentTarget

    this.updatePreview(field);
  }

  updatePreview(field){
    // Get the form data
    const formData = new FormData(this.element);

    formData.append('chat_id', 'preview')
    formData.append('import_id', this.element.dataset.importId)
    formData.delete('authenticity_token')
    formData.delete('_method')

    // Ajax call to retrieve the Chat Preview component
    const url = `${window.location.origin}/connectors/chats/conversations/chat_preview`

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: formData
      })
    .then(response => response.json())
    .then(data => {
      if (data['flash']){
        document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
        return;
      }

      this.previewPanelTarget.innerHTML = data['content']
    });
  }
}
