import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select"
import { projectName } from '../components/utilities'

export default class extends Controller {
  static targets = ['existingSelect', 'additionalSelect', 'additionalWrapper',
                    'freeWrapper', 'freeValueSelect', 'additionalTpl', 'freeTpl', 'dataTypeInput', 'dataTypeInstruction', 'submitBtn']

  connect(){
    this.initializeSelectInstances()

    this.updateOptions()
  }

  initializeSelectInstances(){
    this.initializeAdditionalSelect()
    this.initializeExistingSelects()
    this.freeValueSelectTargets.forEach(selectWrapper => {
      this.initializeFreeValueSelect(selectWrapper)
    })
  }

  initializeAdditionalSelect(){
    this.additionalSelectInstance = initializeSelect({
      selector: this.additionalSelectTarget,
      multiple: false,
      config: JSON.parse(this.additionalSelectTarget.dataset.config || '{}'),
      otherSettings: {
        options: JSON.parse(this.additionalSelectTarget.dataset.options),
        onItemAdd: (value) => {
          this.element.dataset.type === 'free-typing' ? this.addFreeTypingField() : this.addField()
        }
      }
    })
  }

  initializeExistingSelects(){
    this.existingSelectInstances = []

    this.existingSelectTargets.forEach(select => {
      const tomInstance = initializeSelect({
        selector: select,
        multiple: false,
        otherSettings: {
          options: JSON.parse(select.dataset.options),
          items: JSON.parse(select.dataset.items),
          onFocus: () => {
            this.previousValue = select.tomselect.getValue()
          },
          onItemAdd: (value) => {
            tomInstance.blur()
            this.toggleFreeValueInput(value, select, true)
            this.updateSelects(tomInstance)
          },
          onItemRemove: (value) => {
            this.updateSelects(tomInstance)
          },
          render: {
            option: function(data, escape) {
              const specClass = (data.value === 'free_value') ? 'text-orange-400' : ''
              return `<div class="${specClass}">` + escape(data.text) + '</div>';
            },
            item: function(data, escape) {
              const specClass = (data.value === 'free_value') ? 'text-orange-400' : ''
              return `<div class="${specClass}">` + escape(data.text) + '</div>';
            }
          }
        }
      })

      this.existingSelectInstances.push(tomInstance)

      setTimeout(() => {
        this.toggleFreeValueInput(tomInstance.getValue(), select, false)
      }, 100)
    })
  }

  initializeFreeValueSelect(selectWrapper){
    const select = selectWrapper.querySelector('select')

    // Initialize free value Select
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/conversations/search`;

    const ajaxParams = {
      url: url,
      input: {filter_type: select.dataset.column}
    }

    let otherSettings = {
      preload: false,
      onItemAdd: (value, item) => {
        tomInstance.blur()
        select.dataset.items = value
      }
    }

    // Add selected element if present
    const selectedItem = select.dataset.items;
    if (selectedItem !== '') {
      const options = [{text: selectedItem, value: selectedItem}]

      otherSettings['items'] = selectedItem
      otherSettings['options'] = options
    }

    const tomInstance = initializeSelect({
      selector: select,
      config: { addable: true },
      ajaxParams: ajaxParams,
      otherSettings: otherSettings
    });
  }

  updateOptions(){
    // EXISTING SELECTS
    // List all the existing matches
    const selectedValues = []
    this.existingSelectInstances.forEach(tomInstance => {
      const value = tomInstance.getValue()
      if(value !== '') selectedValues.push(value)
    })

    // For each existing select, we remove the existing matches form the available options,
    // except the one that is selected in this select
    this.existingSelectInstances.forEach(tomInstance => {
      const valuesToRemove = selectedValues.filter(el => el !== tomInstance.getValue() && el !== 'free_value')
      valuesToRemove.forEach(value => tomInstance.removeOption(value))
    })

    // ADDITIONAL SELECT
    const additionalValues = []
    document.querySelectorAll('[data-additional-tag]').forEach(tag => {
      additionalValues.push(tag.dataset.fieldId)
    })
    const initialOptions = JSON.parse(this.additionalSelectTarget.dataset.options);
    const optionsToDelete = initialOptions.filter(option => {
      const fieldId = JSON.parse(option['value'])['field_id']
      return selectedValues.concat(additionalValues).includes(fieldId)
    })
    const valuesToDelete = optionsToDelete.map(option => option['value'])
    valuesToDelete.forEach(value => this.additionalSelectInstance.removeOption(value))
  }

  // Triggered by tom-select instance of import_names --> show/hide free value input
  toggleFreeValueInput(value, select, focusOn){
    const importWrapper = select.closest('[data-project-metadata]')
    const freeValueWrapper = importWrapper.querySelector('[data-free-select]')
    const freeValueInstance = freeValueWrapper.querySelector('select').tomselect

    if (value === 'free_value'){
      freeValueWrapper.classList.remove('hidden')
      freeValueInstance.enable()
      if (focusOn) freeValueInstance.focus()
    } else {
      freeValueWrapper.classList.add('hidden')
      freeValueInstance.value = ''
      freeValueInstance.disable()
    }
  }

  updateSelects(select){
    const newValue = select.getValue()
    if (newValue === 'free_value') return;

    // Remove the changed tomInstance from the list of selects
    const selectsToUpdate = this.existingSelectInstances.filter(tomInstance => tomInstance.input !== select.input)

    // Update all the other selects
    selectsToUpdate.forEach(tomInstance => {
      // We remove the new selected value from all the other selects
      tomInstance.removeOption(newValue)

      // We add the previous value to all the other selects
      if (this.previousValue !== '') {
        const selector = tomInstance.input
        const initialOptions = JSON.parse(selector.dataset.options)
        const previousOption = initialOptions.filter(option => option['value'] === this.previousValue)[0]
        tomInstance.addOption(previousOption)
      }
    })

    // Update the additional select
    const initialOptions = JSON.parse(this.additionalSelectTarget.dataset.options);
    if (newValue !== ''){
      const newOption = initialOptions.filter(option => JSON.parse(option['value'])['field_id'] === newValue)[0]
      this.additionalSelectInstance.removeOption(newOption['value'])
    }
    if (this.previousValue !== '') {
      const previousOption = initialOptions.filter(option => JSON.parse(option['value'])['field_id'] === this.previousValue)[0]
      this.additionalSelectInstance.addOption(previousOption)
    }

    // Remove from the additional tag list, any tag corresponding to the selected metadata
    if (newValue === '') return
    const tag = document.querySelector(`[data-additional-tag][data-field-id="${newValue}"]`)
    if (tag) tag.remove()

    // Update the submit button
    this.updateAdditionalTagState()
  }

  // Add additional fields
  addField(){
    const value = this.additionalSelectInstance.getValue()
    if (value === '') return
    const parsedValue = JSON.parse(value)

    // Add new tag
    const newTag = this.additionalTplTarget.innerHTML
                                 .replace(/FIELD_NAME/g, parsedValue['field_name'])
                                 .replace(/FIELD_ID/g, parsedValue['field_id'])
                                 .replace(/DATA_TYPE/g, parsedValue['type'])

    this.additionalWrapperTarget.insertAdjacentHTML('beforeend', newTag)

    // Remove from additional Select the option
    this.additionalSelectInstance.removeOption(value)

    // Update the datatype check
    this.updateAdditionalTagState()
  }

  // Add additional fields when coming from column matching with free typing
  addFreeTypingField(){
    const value = this.additionalSelectInstance.getValue()
    if (value === '') return

    // Add new tag
    const newTag = this.additionalTplTarget.innerHTML
                                 .replace(/FIELD_NAME/g, value)
                                 .replace(/FIELD_ID/g, value)
                                 .replace(/DATA_TYPE/g, '')

    this.additionalWrapperTarget.insertAdjacentHTML('beforeend', newTag)

    // Remove from additional Select the option
    this.additionalSelectInstance.removeOption(value)

    // Update the datatype check
    this.updateAdditionalTagState()
  }

  // remove additional fields
  removeField(){
    event.stopPropagation()

    // Add option to additional select
    const tagToDelete = event.currentTarget.closest('[data-additional-tag]')
    const fieldId = tagToDelete.dataset.fieldId
    const additionalOptions = JSON.parse(this.additionalSelectTarget.dataset.options)
    const optionToAdd = additionalOptions.filter(option => JSON.parse(option['value'])['field_id'] === fieldId)[0]
    this.additionalSelectInstance.addOption(optionToAdd)

    // Update the datatype check
    this.updateAdditionalTagState()
  }

  addFreeField(){
    const newFreeField = this.freeTplTarget.innerHTML

    this.freeWrapperTarget.insertAdjacentHTML('beforeend', newFreeField)
  }

  removeFreeField(){
    event.currentTarget.closest('[data-free-field]').remove()
  }

  updateAdditionalTagState(){
    if (!this.hasDataTypeInputTarget) {
      this.dataTypeInstructionTarget.classList.add('hidden')
      this.submitBtnTarget.classList.remove('btn-disabled')
      return
    }

    setTimeout(() => {
      const dataTypes = this.dataTypeInputTargets.map(input => {
        // Return the value
        return input.value
      })

      if (dataTypes.some(el => el === '')) {
        this.dataTypeInstructionTarget.classList.remove('hidden')
        this.submitBtnTarget.classList.add('btn-disabled')
      } else {
        this.dataTypeInstructionTarget.classList.add('hidden')
        this.submitBtnTarget.classList.remove('btn-disabled')
      }
    }, 100)
  }
}