import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['normalAuthor', 'automation']

  toggleAutomations(){
    if (this.element.dataset.state === 'shown'){
      this.automationTargets.forEach(el => el.classList.add('hidden'))
      this.normalAuthorTargets.filter(el => el.dataset.type === 'automation').forEach(el => el.classList.remove('hidden'))
      this.element.dataset.state = 'hidden'
      event.currentTarget.classList.remove('display-btn-selected')
      event.currentTarget.classList.add('display-btn')
    } else {
      this.automationTargets.forEach(el => el.classList.remove('hidden'))
      this.normalAuthorTargets.filter(el => el.dataset.type === 'automation').forEach(el => el.classList.add('hidden'))
      this.element.dataset.state = 'shown'
      event.currentTarget.classList.add('display-btn-selected')
      event.currentTarget.classList.remove('display-btn')
    }
  }

}