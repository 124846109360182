import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  updateMetrics(){
    // Update page state
    const stateEvent = new CustomEvent("state-updated", { detail: { time_frame: event.currentTarget.value } });
    window.dispatchEvent(stateEvent);

    // Update metrics
    const timeFrameUpdateEvent = new CustomEvent("time-frame-updated");
    window.dispatchEvent(timeFrameUpdateEvent);
  }
}