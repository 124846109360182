import { Controller } from "@hotwired/stimulus"
import { projectName } from '../../../../components/utilities';

export default class extends Controller {
  static targets = ['button', 'list']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  removeVerbatims(){
    // Show loader and disable submit button
    event.currentTarget.classList.add('btn-disabled')
    event.currentTarget.querySelector('[data-loading]').classList.remove('hidden')
    event.currentTarget.querySelector('[data-icon]').classList.add('hidden')
    
    const listId = event.currentTarget.dataset.button;

    const list = this.element.querySelector(`#${listId}`)

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/remove_ideas`;
    const body = { 
      page_state: this.pageState(), 
      data: JSON.parse(list.dataset.data),
      list_id: listId
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Topic panel display
      const topicPanelEvent = new CustomEvent("topic-panel-refreshed", { detail: { data: data } });
      window.dispatchEvent(topicPanelEvent);
      
      // Reset list action bar
      const resetListActionEvent = new CustomEvent("reset-list-actions", { detail: { list_action_id: 'cluster-panel-list-bar' } });
      window.dispatchEvent(resetListActionEvent);

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    });
  }
}
