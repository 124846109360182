import { Controller } from "@hotwired/stimulus"
import setTooltips from '../components/app_tooltips';

export default class extends Controller {
    static targets = ['appNameInput', 'dataTypeInput', 'icon', 'tagWrapper'];

    connect(){
        setTooltips(this.element);
        // Everytime a new tag is loaded we check the data type input and update the colors accordingly
        this.updateTagColor();
    }

    updateAppNameInput(){
        this.appNameInputTarget.value = event.currentTarget.value;
    }

    updateDataTypeInput(){
        this.dataTypeInputTarget.value = event.currentTarget.value;
        this.updateTagColor();
    }

    updateTagColor(){
        if (this.dataTypeInputTarget.value === ''){
            this.tagWrapperTarget.classList.remove('border-green-500', 'text-green-500')
            this.tagWrapperTarget.classList.add('border-orange-400', 'text-orange-400')
            this.iconTarget.classList.remove('fa-circle-check')
            this.iconTarget.classList.add('fa-circle-question')
        } else {
            this.tagWrapperTarget.classList.add('border-green-500', 'text-green-500')
            this.tagWrapperTarget.classList.remove('border-orange-400', 'text-orange-400')
            this.iconTarget.classList.add('fa-circle-check')
            this.iconTarget.classList.remove('fa-circle-question')
        }
    }

    removeTag(){
        this.element.remove()
    }
}