import { Controller } from "@hotwired/stimulus"

import { removeContent, projectName } from '../components/utilities';
import initializeSelect from "../components/initialize_select";
import { openModal, checkConsistency } from "../components/modal";

export default class extends Controller {
	static targets = ["title", "changeTitle", "validate", "inputTitle", "titleSubmit", "loader",
						"addToClusterModal", "addToClusterSelect",
						"createModal", "createClusterInput",
						"removeIdeasModal",
						"deleteConversations",
            "clusterSplit",
            "mergeClustersModal", "createLinkModal", "destroyLinksModal", "addMacroClusterModal", "addOperationTagModal", "ignoreIdeasModal", 'updateSentimentModal', 'addClusterAiAgentsBulkModal', 'removeClusterActionModal',
            "changeStateModal", 'selectedState',
            "clusterTagTpl",
            "modalClusterList", "clusterCount", "clusterTable", "mergeClusterName",
            "clusterLabel",
            "macroClusterSelect", "tagSelect", "aiAgentSelect", "aiAgentMode",
            "commentBtns", 'commentInput',
            'extendWrapper', 'extendButton', 'extendList', 'extendInstruction']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]')?.dataset?.pageState || '{}')
  }

  connect(){
  }

 	// MODAL MANAGEMENT___________________________________________________________________________________________________

	closeModal(){
		MicroModal.close(this.activeModal.id);
	}

	securityCheck(){
		const input = this.activeModal.querySelector('#security_check');
		const submit = this.activeModal.querySelector('#submit');

		checkConsistency(input, submit);
	}

  // Update merge modal and destroy link modal (cluster tag list + input + key figures)
  updateModalClustersList(activeModal, clusters) {
    const clusterList = this.modalClusterListTargets.find(element => activeModal.contains(element));

    // We remove the old list
    removeContent(clusterList);

    // We iterate through clusters to add cluster tags to list and cluster id to input
    clusters.forEach(cluster => {
      // Add new tags to the merge List
      let content = this.clusterTagTplTarget.innerHTML;

      content = content.replace(/CLUSTER_ID/g, cluster['id'])
                       .replace(/CLUSTER_NAME/g, cluster['cluster_name'])

      clusterList.insertAdjacentHTML('beforeend', content);
    })

    // Update the count of clusters and the total impact rate
    this.clusterCountTarget.innerText = clusters.length;
  }

  // We create the cluster list from cluster-list table - input: event.currentTarget button - output : array of Hash
  getClustersData(buttonDataset){
    const clustersData = [];
    document.querySelectorAll('#clusters_index_clusters_list table tr').forEach(tr => {
      const id = tr.id.replace("clusters-", "");

      if (id === '') return;

      // is contained in selection?
      const buttonData = JSON.parse(buttonDataset.data)
      const listSelection = buttonData.selection.selected_ids;
      const listException = buttonData.selection.excepted_ids;
      const allSelected = listSelection[0] === 'all';
      const isSelectedId = allSelected ? !listException.includes(id) : listSelection.includes(id);

      if (isSelectedId) {
        const clusterData = {};
        clusterData['id'] = id;
        clusterData['cluster_name'] = tr.children[2].querySelector('div').innerHTML.trim().replace(/&nbsp;/g, '');
        clustersData.push(clusterData);
      }
    })

    return clustersData;
  }

  // MINIMIZE CLUSTER___________________________________________________________________________________________________

  minimizeCluster(){
    const clusterId = event.currentTarget.dataset.clusterId;
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${clusterId}/minimize`;
    const verb = "POST";
    const body = {};

    this.AJAXcall(url, verb, body);

    const button = event.currentTarget
    const originIcon = button.querySelector('[data-icon]');
    const loadIcon = button.querySelector('[data-loading]');
    originIcon.classList.add('hidden');
    loadIcon.classList.remove('hidden');
  }

	// CHANGE CLUSTER NAME________________________________________________________________________________________________

	// Show the input to change th title of the cluster
	showTitleInput(){
		this.titleTarget.classList.add('hidden');
    this.changeTitleTarget.classList.remove('hidden');
    this.inputTitleTarget.removeAttribute("disabled");
	}

  // Validates that the input is not empty in order to display the submit button
  toggleTitleSubmit(){
    if(this.inputTitleTarget.value.trim().length === 0) {
      // If the input is empty or whitespace deactivate the submit button and show a tooltip
      this.titleSubmitTarget.classList.remove("hover:bg-green-400","cursor-pointer");
      this.titleSubmitTarget.classList.add("btn-disabled");
    }else{
      this.titleSubmitTarget.classList.add("hover:bg-green-400", "cursor-pointer");
      this.titleSubmitTarget.classList.remove("btn-disabled");
    }
  }

	// Change title
	changeTitle(){
		const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${this.inputTitleTarget.dataset.clusterId}/update_name`;
		const verb = "POST";
		const body = {
			cluster_name: this.inputTitleTarget.value
		};

		this.AJAXcall(url, verb, body);

		// Add spinner to the check button
    const button = event.currentTarget
    button.classList.add('btn-disabled')
		const icon = button.firstElementChild;
		icon.classList.remove('fa-check')
		icon.classList.add('fa-circle-notch', 'fa-spin');
	}

  // Delete cluster
  deleteCluster(){
    const destroyConfirmation = confirm(event.currentTarget.dataset.confirm)
    if (!destroyConfirmation) return;

		const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${this.inputTitleTarget.dataset.clusterId}`;
		const verb = "DELETE";
		const body = {
			origin: this.element.dataset.origin
		};

		this.AJAXcall(url, verb, body);

		// Add spinner to the check button
    const button = event.currentTarget
    button.classList.add('btn-disabled')
		const icon = button.firstElementChild;
		icon.classList.remove('fa-check')
		icon.classList.add('fa-circle-notch', 'fa-spin');
	}

	// ADD THE SELECTED IDEAS TO A SPECIFIC CLUSTER____________________________________________________________________

	// Open the modal that ask for the change
	addToClusterModal(){
    const tomInstance = this.addToClusterSelectTarget.tomselect;
    if (tomInstance !== undefined) tomInstance.destroy();

    // Initialize clusters selection for add_to_cluster modal
    initializeSelect({
      selector: this.addToClusterSelectTarget,
      config: JSON.parse(this.addToClusterSelectTarget.dataset.config),
      otherSettings: {
        searchField: ['cluster', 'macro_cluster'],
        render: {
          option: function(data, escape) {
            const macro_cluster = (data.macro_cluster === null) ? '' : `${escape(data.macro_cluster)}`

            return `<div class="cursor-pointer flex justify-start items-center gap-2">
                      <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full ${(macro_cluster === '') ? 'hidden' : ''}" style="background-color:${macro_cluster === '' ? '' : data.color}"></div>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}" style="color:${macro_cluster === '' ? '' : data.color}">${macro_cluster}</span>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}">-</span>
                      <span>${escape(data.cluster)}</span>
                      ${(data.state === 'validated') ? '<i class="fa-light fa-circle-check text-sm text-green-500"></i>' : ''}
                    </div>`
          },
          item: function(data, escape) {
            const macro_cluster = (data.macro_cluster === null) ? '' : `${escape(data.macro_cluster)}`

            return `<div class="cursor-pointer flex justify-start items-center gap-2">
                      <div class="flex-shrink-0 w-2 h-2 mr-1 rounded-full ${(macro_cluster === '') ? 'hidden' : ''}" style="background-color:${macro_cluster === '' ? '' : data.color}"></div>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}" style="color:${macro_cluster === '' ? '' : data.color}">${macro_cluster}</span>
                      <span class="${(macro_cluster === '') ? 'hidden' : ''}">-</span>
                      <span>${escape(data.cluster)}</span>
                    </div>`
          }
        },
        onItemAdd: (value, item) => {
          this.extendWrapperTarget.classList.remove('hidden');
          this.extendButtonTarget.classList.remove('hidden');
          this.extendInstructionTarget.classList.add('hidden');
          removeContent(this.extendListTarget);
        },
        onItemRemove: () => {
          this.extendWrapperTarget.classList.add('hidden');
          this.extendInstructionTarget.classList.add('hidden');
          removeContent(this.extendListTarget);
        }
      }
    });

    // Reset the extend selection
    this.extendWrapperTargets.forEach(wrapper => wrapper.classList.add('hidden'));
    this.extendListTargets.forEach(list => removeContent(list));

		this.activeModal = this.addToClusterModalTarget;
  	openModal(this.activeModal, 10, event.currentTarget);
	}

	// Send the request to add selected ideas to the cluster
	addToCluster(){
		const allowedAction = event.currentTarget.dataset.allowed === "true";
		if (!allowedAction) return;

		const cluster_name = this.addToClusterSelectTarget.value;

		if (cluster_name === ""){
			// Show error message
			this.activeModal.querySelector("[data-flash]").classList.remove('hidden')
		} else {
			// Hide error message
			this.activeModal.querySelector("[data-flash]").classList.add('hidden')

			// Launch request
			const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/add_ideas`;
			const verb = "POST";
			const body = {
        ...this.concatenateSelection(this.activeModal),
				origin: this.element.dataset.origin,
				cluster_name: cluster_name,
			};

			this.AJAXcall(url, verb, body);

			// Add spinner to the check button and disable it
      const button = event.currentTarget
      button.classList.add('btn-disabled')
			const icon = button.firstElementChild;
			icon.classList.remove('hidden');
		}
	}

  concatenateSelection(modal){
    const requestData = JSON.parse(modal.dataset.requestData)

    const extensionList = modal.querySelector('[data-list^="extend-selection-list"] [data-controller="list"]')
    if (!extensionList) return requestData;

    requestData.data.extended_data = JSON.parse(extensionList.dataset.data)

    return requestData;
  }

	// CREATE A CLUSTER WITH SELECTED IDEAS____________________________________________________________________________

	openCreateClusterModal(){
		this.activeModal = this.createModalTarget;

    // Reset the extend selection
    this.extendButtonTargets.forEach(btn => btn.classList.remove('hidden'));
    this.extendInstructionTargets.forEach(instruction => removeContent(instruction));
    this.extendListTargets.forEach(list => removeContent(list));
    this.createClusterInputTarget.value = '';

		openModal(this.activeModal, 10, event.currentTarget);
	}

	createCluster(){
		const allowedAction = event.currentTarget.dataset.allowed === "true";
		if (!allowedAction) return;

		const cluster_name = this.createClusterInputTarget.value;

		if (cluster_name === ""){
			// Show error message
			this.activeModal.querySelector("[data-flash]").classList.remove('hidden')
		} else {
			// Hide error message
			this.activeModal.querySelector("[data-flash]").classList.add('hidden')

			// Launch request
			const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters`;
			const verb = "POST";
      const body = {
        ...this.concatenateSelection(this.activeModal),
        origin: this.element.dataset.origin,
        cluster_name: cluster_name,
      };

			this.AJAXcall(url, verb, body);

      // Add spinner to the check button and disable it
      const button = event.currentTarget
      button.classList.add('btn-disabled')
      const icon = button.firstElementChild;
      icon.classList.remove('hidden');
		}
	}

	// REMOVE IDEAS FROM A SPECIFIC CLUSTER____________________________________________________________________________

	// Open the modal that ask for the change
	removeIdeasModal(){
		this.activeModal = this.removeIdeasModalTarget;
		openModal(this.activeModal, 10, event.currentTarget);
	}

	// Send the request to change the cluster of selected idea
	removeIdeas(){
		const allowedAction = event.currentTarget.dataset.allowed === "true";
		if (!allowedAction) return;

		// Launch request
		const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/remove_ideas`;
		const verb = "POST";
    const body = {
      ...JSON.parse(this.activeModal.dataset.requestData),
      origin: this.element.dataset.origin,
    };

		this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
	}

  // MERGE CLUSTERS_____________________________________________________________________________________________________

  // Open the modal that ask for the change
  mergeClustersModal(){
    this.activeModal = this.mergeClustersModalTarget;
    const clustersData = this.getClustersData(event.currentTarget.dataset);

    this.mergeClusterNameTarget.value = ''
    // function to take checked cluster name and create tag (modal management)
    this.updateModalClustersList(this.activeModal, clustersData);
    openModal(this.activeModal, 2, event.currentTarget);
  }

  // Send the request to add selected idea to the cluster
  mergeClusters(){
    const allowedAction = event.currentTarget.dataset.allowed === "true";
    if (!allowedAction) return;

    // if cluster name field is empty, show flash message
    if (this.mergeClusterNameTarget.value === "") {
      // Show error message
      this.activeModal.querySelector("[data-flash]").classList.remove('hidden');
      return;
    }

    // Hide error message
    this.activeModal.querySelector("[data-flash]").classList.add('hidden');

    const message = document.getElementById('confirmation-message').innerHTML;
    if (confirm(message)){
      // Launch request
      const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/merge`;
      const verb = "POST";
      const body = {
        cluster_name: this.mergeClusterNameTarget.value,
        ...JSON.parse(this.activeModal.dataset.requestData)
      };

      this.AJAXcall(url, verb, body);

      // Add spinner to the check button and disable it
      const button = event.currentTarget
      button.classList.add('btn-disabled')
      const icon = button.firstElementChild;
      icon.classList.remove('hidden');
    }
  }

  // CHANGE STATE_____________________________________________________________________________________________________

  // Open the modal that ask for the change
  changeStateModal(){
    this.activeModal = this.changeStateModalTarget;
    const clustersData = this.getClustersData(event.currentTarget.dataset);

    // function to take checked cluster name and create tag (modal management)
    this.updateModalClustersList(this.activeModal, clustersData);
    openModal(this.activeModal, 2, event.currentTarget);
  }

  changeState(){
    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/change_state_to_many`;
    const verb = "POST";
    const body = {
      new_state: this.selectedStateTarget.value,
      ...JSON.parse(this.activeModal.dataset.requestData)
    };

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  // CREATE LINKS_______________________________________________________________________________________________________

  // Send the request to create link between clusters
  createLink(){
    const actionBtn = event.currentTarget;

    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/create_link`;
    const verb = "POST";
    const body = {
      list_id: actionBtn.dataset.listId,
      data:  JSON.parse(actionBtn.dataset.data)
    };

    this.AJAXcall(url, verb, body);
  }

  // DESTROY LINK_______________________________________________________________________________________________________

  // Open the modal that ask for the change
  destroyLinksModal(){
    const actionBtn = event.currentTarget
    const selectedCount = JSON.parse(actionBtn.dataset.data).selection.selected_count;

    if (selectedCount > 5) {
      // Open modal
      this.activeModal = this.destroyLinksModalTarget;
      const clustersData = this.getClustersData(event.currentTarget.dataset);
      // function to take checked cluster name and create tag (modal management)
      this.updateModalClustersList(this.activeModal, clustersData);
      openModal(this.activeModal, 5, event.currentTarget);
    } else {
      // Launch action without opening modal
      const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/remove_links`;
      const verb = "POST";
      const body = {
        list_id: actionBtn.dataset.listId,
        data: JSON.parse(actionBtn.dataset.data)
      };

      this.AJAXcall(url, verb, body);
    }
  }

  // Send the request to destroy links between selected clusters
  destroyLinks(){
    const allowedAction = event.currentTarget.dataset.allowed === "true";
    if (!allowedAction) return;

    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/remove_links`;
    const verb = "POST";
    const body = JSON.parse(this.activeModal.dataset.requestData)

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  // ADD MACRO CLUSTER_______________________________________________________________________________________________________

  // Open the modal that ask for the change of Macro Cluster
  addMacroClusterModal(){
    this.activeModal = this.addMacroClusterModalTarget;
    const clustersData = this.getClustersData(event.currentTarget.dataset);
    // function to take checked cluster name and create category (modal management)
    this.updateModalClustersList(this.activeModal, clustersData);

    // Initialize the select categories (tag and macro_cluster)
    const controllerDiv = this.activeModal.querySelector('[data-controller="cluster-category"]');
    let controller = this.application.getControllerForElementAndIdentifier(controllerDiv, "cluster-category");
    controller.initializeSelectClusterCategory();

    // Initialize the pickr instance (useful when creating new category --> color instance)
    this.pickr = {};

    openModal(this.activeModal, 1000, event.currentTarget);
  }

  addMacroCluster(){
  	const allowedAction = event.currentTarget.dataset.allowed === "true";
  	if (!allowedAction) return;

  	// Launch request
  	const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/add_macro_cluster_to_many`;
  	const verb = "POST";
  	const body = JSON.parse(this.activeModal.dataset.requestData)

  	// Add the tag selection to the body
  	const select = this.macroClusterSelectTarget.querySelector('[data-select]')
  	const nameInput = this.macroClusterSelectTarget.querySelector('[data-name-input-category]')
  	const colorInput = this.macroClusterSelectTarget.querySelector('[data-color-input-category]')

    // Check if the value is a string or a num.
    // If num --> The record already exists
    // If not --> the user just created it and we need to add it the category list
    if (isNaN(select.value)){
  		Object.assign(body, {
  			mode: 'create',
  			name: nameInput.value,
  			color: colorInput.value
  		})
  	} else {
  		Object.assign(body, {
  			mode: 'add',
  			macro_cluster_id: select.value
  		})
  	}

  	this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  // ADD TAG________________________________________________________________________________________________________________

  // Open the modal that asks for the change
  addOperationTagModal(){
    this.activeModal = this.addOperationTagModalTarget;
    const clustersData = this.getClustersData(event.currentTarget.dataset);
    // function to take checked cluster name and create category (modal management)
    this.updateModalClustersList(this.activeModal, clustersData);

    // Initialize the select of categories
    const controllerDiv = this.activeModal.querySelector('[data-controller="cluster-category"]');
    let controller = this.application.getControllerForElementAndIdentifier(controllerDiv, "cluster-category");
    controller.initializeSelectClusterCategory();

    // Initialize the pickr instance (useful when creating new category --> color instance)
    this.pickr = {};

    openModal(this.activeModal, 1000, event.currentTarget);
  }

  addTag(){
  	const allowedAction = event.currentTarget.dataset.allowed === "true";
  	if (!allowedAction) return;

  	// Launch request
  	const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/add_tag_to_many`;
  	const verb = "POST";
  	const body = JSON.parse(this.activeModal.dataset.requestData)

  	// Add the tag selection to the body
  	const select = this.tagSelectTarget.querySelector('[data-select]')
  	const nameInput = this.tagSelectTarget.querySelector('[data-name-input-category]')
  	const colorInput = this.tagSelectTarget.querySelector('[data-color-input-category]')

    // Check if the value is a string or a num.
    // If num --> The record already exists
    // If not --> the user just created it and we need to add it the category list
    if (isNaN(select.value)){
  		Object.assign(body, {
  			mode: 'create',
  			name: nameInput.value,
  			color: colorInput.value
  		})
  	} else {
  		Object.assign(body, {
  			mode: 'add',
  			tag_id: select.value
  		})
  	}

  	this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  selectClusterCategory(){
    this.categoryType = event.currentTarget.closest('[data-controller="cluster-category"]').dataset.category
  	const value = event.currentTarget.value;

  	// Define create div
  	const categorySelect = this.categoryType === 'tag' ? this.tagSelectTarget : this.macroClusterSelectTarget
  	const createDiv = categorySelect.querySelector('[data-create-div]')

    // Check if the value is a string or a num.
    // If num --> The record already exists
    // If not --> the user just created it and we need to add it the category list
    if (isNaN(value)){
  	  createDiv.classList.remove('hidden');

      // Initialize name input
      const nameInput = createDiv.querySelector('#name')
      nameInput.value = value;
      nameInput.focus();
  	} else {
  	  createDiv.classList.add('hidden');
  	}
  }

  // ADD AI AGENTS_______________________________________________________________________________________________________

   // Open the modal that asks for the change
  addClusterAiAgentsBulkModal(){
    this.activeModal = this.addClusterAiAgentsBulkModalTarget;
    const clustersData = this.getClustersData(event.currentTarget.dataset);
    // function to take checked cluster name and create category (modal management)
    this.updateModalClustersList(this.activeModal, clustersData);

    this.aiAgentSelectTarget.tomselect.clear()
    this.aiAgentModeTarget.checked = true

    openModal(this.activeModal, 1000, event.currentTarget);
  }

  addClusterAiAgentsBulk(){
    let message = event.currentTarget.dataset.message;
    if (!confirm(message)) return;
  
    let url;
    if (this.aiAgentModeTarget.checked){
      url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/add_ai_agent_to_many`;
    } else {
      url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/remove_ai_agent_to_many`;
    }

    const verb = 'POST'
    const body = {
      ...JSON.parse(this.activeModal.dataset.requestData),
      ai_agent_ids: this.aiAgentSelectTarget.tomselect.getValue()
    }

    this.AJAXcall(url, verb, body);
  }

  // IGNORE IDEAS____________________________________________________________________________________________________

  // Open the modal that ask for the change
  ignoreIdeasModal(){
    this.activeModal = this.ignoreIdeasModalTarget;

    // Reset the extend selection
    this.extendButtonTargets.forEach(btn => btn.classList.remove('hidden'));
    this.extendInstructionTargets.forEach(instruction => removeContent(instruction));
    this.extendListTargets.forEach(list => removeContent(list));

    openModal(this.activeModal, 10, event.currentTarget);
  }

  // Send the request to ignore idea
  ignoreIdeas(){
    const allowedAction = event.currentTarget.dataset.allowed === "true";
    if (!allowedAction) return;

    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/ideas/ignore`;
    const verb = "POST";
    const body = this.concatenateSelection(this.activeModal);

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget
    button.classList.add('btn-disabled')
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  // UPDATE SENTIMENT________________________________________________________________________________________________

  // Open the modal that ask for the change
  updateSentimentModal(){
    this.activeModal = this.updateSentimentModalTarget;

    this.resetEmoji()

    // Reset the extend selection
    this.extendButtonTargets.forEach(btn => btn.classList.remove('hidden'));
    this.extendInstructionTargets.forEach(instruction => removeContent(instruction));
    this.extendListTargets.forEach(list => removeContent(list));

    openModal(this.activeModal, 10, event.currentTarget);
  }

  resetEmoji(){
    const emojiIcons = this.activeModal.querySelectorAll('[data-sentiment]');
    emojiIcons.forEach( icon => {
      icon.classList.remove('font-extrabold', 'opacity-85', 'opacity-25');
      icon.removeAttribute('selected');
    })
  }

  // Send the request to update sentiment for the selected idea
  updateSentiment(){
    const allowedAction = event.currentTarget.dataset.allowed === "true";
    if (!allowedAction) return;

    // Launch request
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/ideas/update_sentiment_bulk`;
    const verb = "POST";
    const body = {
      ...this.concatenateSelection(this.activeModal),
      sentiment: this.sentiment
    };

    this.AJAXcall(url, verb, body);

    // Add spinner to the check button and disable it
    const button = event.currentTarget;
    button.classList.add('btn-disabled');
    const icon = button.firstElementChild;
    icon.classList.remove('hidden');
  }

  toggleSentiment(){
    // First, gray-out all the emoji icons
    const emojiContainer = event.currentTarget.parentElement;
    const emojiElements = Array.from(emojiContainer.children);
    emojiElements.forEach(element => this.grayoutUnselected(element));

    // Second, highlight only the selected emoji
    this.highlightSelected();
  }

  highlightSelected(){
    this.sentiment = event.currentTarget.dataset.sentiment;
    event.currentTarget.classList.add('font-extrabold','opacity-85');
    event.currentTarget.setAttribute('selected','true');
    event.currentTarget.classList.remove('opacity-25');
  }

  grayoutUnselected(element){
    element.classList.add('opacity-25');
    element.removeAttribute('selected');
    element.classList.remove('font-extrabold','opacity-85');
  }

	// AJAX CALL TO UPDATE CLUSTERS_______________________________________________________________________________________

	// AJAX call to update cluster or idea
	AJAXcall(url, verb, body){
		// Get scopes filters (only for index page, not for show page)
		const filterBar = document.querySelector("[data-controller='filter-bar']");
		const filters = filterBar ? filterBar.dataset.filters : "[]";

		body = {...body, filters: filters, page_state: this.pageState()}

		const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
		    .content.value;
		fetch(url, {
		    method: verb,
		    headers: {
		      Accept: "application/js",
		      "Content-Type": "application/json",
		      "X-CSRF-Token": csrfToken
		    },
		    credentials: "same-origin",
		    body: JSON.stringify(body)
		  })
		.then(response => response.json())
		.then(data => {
      // Update page state
      const stateEvent = new CustomEvent("state-updated", { detail: data['page_state'] });
      window.dispatchEvent(stateEvent);

      // Map display
      const mapEvent = new CustomEvent("map-updated", { detail: { data: data } });
      window.dispatchEvent(mapEvent);

      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // Topic panel display
      const topicPanelEvent = new CustomEvent("topic-panel-refreshed", { detail: { data: data } });
      window.dispatchEvent(topicPanelEvent);
      
      // Overview macro cluster list
      const macroTopicListEvent = new CustomEvent("macro-topic-tags-updated", { detail: { data: data } });
      window.dispatchEvent(macroTopicListEvent);

      // Overview tag list
      const tagListEvent = new CustomEvent("tags-updated", { detail: { data: data } });
      window.dispatchEvent(tagListEvent);

      // Overview aiAgents list
      const aiAgentListEvent = new CustomEvent("ai-agents-updated", { detail: { data: data } });
      window.dispatchEvent(aiAgentListEvent);

      // Search panel
      const searchPanelEvent = new CustomEvent("search-panel-updated", { detail: { data: data } });
      window.dispatchEvent(searchPanelEvent);

      // Reset list action bar
      const resetListActionEvent = new CustomEvent("reset-list-actions", { detail: { list_action_id: data['list_action_id'] } });
      window.dispatchEvent(resetListActionEvent);

      // Hide (if needed the topic actions panel)
      setTimeout(() => {
        const actionPanelEvent = new CustomEvent("toggle-topic-action-panel");
        window.dispatchEvent(actionPanelEvent);
      }, 1000)

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

      if (this.activeModal) {
          this.closeModal();
          this.activeModal.querySelector('#submit i').classList.add('hidden');
      }
		});
	}
}
