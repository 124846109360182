import { Controller } from "@hotwired/stimulus"
import setTooltips from '../../../../components/app_tooltips';
import { projectName } from '../../../../components/utilities';

export default class extends Controller {

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  connect(){
    setTooltips(this.element);
  }

  closePanel(){
    this.element.classList.add('hidden')

    // Reset page
    const resetEvent = new CustomEvent("reset-page");
    window.dispatchEvent(resetEvent);

    // Unselect ideas from the verbatim list to hide the action bar
    const listSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_cluster_panel_clustered_ideas_list', type: 'ideas', selected_ids: [] } });
    window.dispatchEvent(listSelectionEvent);
  }

  linkAiAgent(){
    const clusterId = event.currentTarget.dataset.clusterId;
    const aiAgentId = event.currentTarget.dataset.aiAgentId;

    let url;
    if (event.currentTarget.checked){
      url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${clusterId}/link_ai_agent`
    } else {
      url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/${clusterId}/unlink_ai_agent`
    }

    const body = { ai_agent_id: aiAgentId, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      // Table display
      const tableEvent = new CustomEvent("table-updated", { detail: { data: data } });
      window.dispatchEvent(tableEvent);

      // AiAgent list in overview panel
      const aiAgentListEvent = new CustomEvent("ai-agents-updated", { detail: { data: data } });
      window.dispatchEvent(aiAgentListEvent);

      // Display flash message
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    });
  }
}