import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../components/initialize_select";

export default class extends Controller {
  static targets = ['select', 'createBtn'];
 
  connect(){
    this.initialiseConnectorSkeletonsSelect()
  }

  initialiseConnectorSkeletonsSelect(){
    this.select = initializeSelect({
      selector: this.selectTarget,
      otherSettings: {
        options: JSON.parse(this.selectTarget.dataset.options),
        items: JSON.parse(this.selectTarget.dataset.items),
        render: {
          item: function(data, escape) {
            return `<div class="flex justify-start items-center gap-4">
                      <div class="flex p-2 rounded bg-gray-100 border shadow">
                        <img class="w-6" src="${data.icon}">
                      </div>
                      <div class="">${escape(data.text)}</div>
                    </div>`
          },
          option: function(data, escape) {
            return `<div class="flex justify-start items-center gap-4">
                      <div class="flex p-2 rounded bg-gray-100 border shadow">
                        <img class="w-6" src="${data.icon}">
                      </div>
                      <div class="">${escape(data.text)}</div>
                    </div>`
          },
        },
        onItemAdd: (value) => {
          this.updateCreateBtn(value);          
        },
      }
    });
  }  

  updateCreateBtn(value){
    this.createBtnTarget.classList.remove('btn-disabled')
    this.createBtnTarget.dataset.href = `/connector_credentials/new?connector_skeleton_id=${value}`
  }

  nextPage(){
    event.preventDefault(); // Empêche le comportement de clic par défaut
    // Get the href from the data-href attribute
    const url = event.currentTarget.dataset.href

    // Redirect to the URL
    window.location.href = url
  }  
}