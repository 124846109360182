import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  
  static targets = ["messagesList", "requesterMessageTpl", "loader", "loaderText", 
                    "autoMessageTpl", "innerAutoMessage", "closingBanner", "escalationForm"]

  chatState(){
    return JSON.parse(document.querySelector('[data-chat-state]').dataset.chatState)
  }

  // Event handlers
  messageAddedHandler = (event) => this.updateMessages(event.detail);
  escalationFormHandler = (event) => this.loadEscalationForm(event.detail);
  createMessageHandler = (event) => this.createMessage(event.detail.description);
  

  connect(){
    window.addEventListener('new-message', this.messageAddedHandler)
    window.addEventListener('escalation-form', this.escalationFormHandler)
    window.addEventListener('create-message', this.createMessageHandler)

    this.scrollToBottom(0);
  }

  disconnect(){
    window.removeEventListener('new-message', this.messageAddedHandler)
    window.removeEventListener('escalation-form', this.escalationFormHandler)
    window.removeEventListener('create-message', this.createMessageHandler)
  }

  // Send the request to add selected extracts to the cluster
	createMessage(description){ 
    if(description.length === 0) return

    const conversationId = this.element.dataset.conversationId
    const url = `${window.location.origin}/connectors/chats/messages`;

    // Add the requester message to the messages list
    this.addRequesterMessage(description)

    // Show the loader
    this.showLoader()

    const body = { 
      ...this.chatState(),
      conversation_id: conversationId,
      message: {
        description: description,
        speaker: 'requester' 
      }
    }

    // AJAX call
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
      .then(response => response.text())
      .then(data => {
        if (this.element.dataset.conversationId === '') this.element.dataset.conversationId = data
      });
  }

  updateMessages(data){
    if (data.conversationId !== parseInt(this.element.dataset.conversationId)) return;

    // Hide the loader
    this.loaderTarget.classList.add('hidden')

    const messageHtml = this.autoMessageTplTarget.innerHTML;
    this.loaderTarget.insertAdjacentHTML('beforebegin', messageHtml);

    this.scrollToBottom(100);

    const lastAutoMessage = this.innerAutoMessageTargets[this.innerAutoMessageTargets.length - 1]

    this.writeAnswer(lastAutoMessage, data.message, 1000)
  }

  loadEscalationForm(data){
    if (data.conversationId !== parseInt(this.element.dataset.conversationId)) return;

    setTimeout(() => {
      const escalationHtml = data.escalationForm
      this.loaderTarget.insertAdjacentHTML('beforebegin', escalationHtml);
    }, 2500)
  }

  scrollToBottom(timer) {
    setTimeout(() => {
      this.messagesListTarget.scrollTop = this.messagesListTarget.scrollHeight; // Scrolls the message container to the bottom
    }, timer); // Adjust the delay if needed
  }

  handleEscalationSuccess(event){
    const [data, _status, _xhr] = event.detail; // Extract JSON response

    this.escalationFormTarget.classList.add('hidden');
    this.updateMessages(data); 
  }

  addRequesterMessage(description){
    const messageHtml = this.requesterMessageTplTarget.innerHTML.replace(/DESCRIPTION/g, description)
    this.loaderTarget.insertAdjacentHTML('beforebegin', messageHtml);
    this.scrollToBottom(100);
  }

  showLoader(){
    setTimeout(() => {
      this.loaderTarget.classList.remove('hidden');
      this.animateLoader()
      this.scrollToBottom(100);
    }, 1000)
  }

  animateLoader(){
    // Liste des textes à afficher
    const texts = JSON.parse(this.loaderTextTarget.dataset.texts);

    let textIndex = 0; // Index actuel dans la liste des textes
    let charIndex = 0; // Position du caractère actuel dans le texte
    let isDeleting = false; // Indique si on est en train d'effacer le texte

    const typeEffect = () => {
      const currentText = texts[textIndex].text; // Texte actuel dans la liste
      const currentDelay = texts[textIndex].delay;

      if (isDeleting) {
        // Effacement du texte
        charIndex--;
        this.loaderTextTarget.textContent = currentText.substring(0, charIndex);
      } else {
        // Affichage du texte caractère par caractère
        charIndex++;
        this.loaderTextTarget.textContent = currentText.substring(0, charIndex);
      }
    
      if (!isDeleting && charIndex === currentText.length) {
        // Fin de l'affichage du texte actuel
        if (textIndex === texts.length - 1) {
          // Si c'est le dernier texte, ne pas effacer
          return;
        }
        isDeleting = true; // Passer à l'état d'effacement
        setTimeout(typeEffect, currentDelay); // Pause avant d'effacer
      } else if (isDeleting && charIndex === 0) {
        // Fin de l'effacement
        isDeleting = false; // Passer au prochain texte
        textIndex++; // Avancer dans la liste des textes
        setTimeout(typeEffect, 500); // Pause avant de commencer le texte suivant
      } else {
        // Continuer à afficher ou effacer
        setTimeout(typeEffect, isDeleting ? 10 : 20); // Vitesse de frappe/effacement
      }
    }

    // Démarrage de l'effet
    typeEffect();
  }

  writeAnswer(element, html, duration) {  
    const tempDiv = document.createElement("div"); // Conteneur temporaire pour parser le HTML
    tempDiv.innerHTML = html; // Insère le HTML dans le conteneur temporaire
    const totalChars = html.length; // Nombre total de caractères dans le HTML brut
    const interval = duration / totalChars; // Temps entre chaque caractère
    let currentCharIndex = 0; // Position actuelle dans le texte brut
  
    const typeText = () => {
      if (currentCharIndex <= totalChars) {
        // Ajoute progressivement du contenu HTML
        element.innerHTML = tempDiv.innerHTML.substring(0, currentCharIndex);
        currentCharIndex++;
        setTimeout(typeText, interval);
      }
    }
  
    element.innerHTML = ""; // Assure que le contenu est vide avant de commencer
    typeText(); // Lance l'effet de frappe
  }
  
}
