import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["fileInput", "preview"];

  connect() {
    this.defaultImage = this.previewTarget.src; // Sauvegarde l'image par défaut
  }

  // Déclenche le clic sur le champ input lorsque l'image est cliquée
  triggerUpload(event) {
    this.fileInputTarget.click();
  }

  // Prévisualisation et upload de l'image
  async upload(event) {
    const file = event.target.files[0];
    if (!file) return;

    // Update preview image
    const reader = new FileReader();
    reader.onload = (e) => {
      this.previewTarget.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }
}
