import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['parameterEmail', 'parameterTicketing']

  showParameter(){
    // We select the strategy 'send_email'
    if (event.currentTarget.value === 'send_email'){
      this.parameterTicketingTargets.forEach(el => {
        el.classList.add('hidden')
        el.querySelector('select').tomselect.disable()
      })
      this.parameterEmailTarget.classList.remove('hidden')
      this.parameterEmailTarget.querySelector('input').disabled = false
    // We select the strategy 'create_ticket'
    } else if (event.currentTarget.value.startsWith('create_ticket')){
      const exportId = event.currentTarget.value.replace('create_ticket_', '')

      this.parameterTicketingTargets.forEach(el => {
        if (el.dataset.exportId === exportId) {
          el.classList.remove('hidden')
          el.querySelector('select').tomselect.enable()
        } else {
          el.classList.add('hidden')
          el.querySelector('select').tomselect.disable()
        }
      })
      this.parameterEmailTarget.classList.add('hidden')
      this.parameterEmailTarget.querySelector('input').disabled = true
    // We do not select any strategy
    } else {
      this.parameterTicketingTargets.forEach(el => {
        el.classList.add('hidden')
        el.querySelector('select').tomselect.disable()
      })
      this.parameterEmailTarget.classList.add('hidden')
      this.parameterEmailTarget.querySelector('input').disabled = true
    }
  }
}
