
// remove all the content inside a container
const removeContent = (contentWrapper) => {
    // Deletion of the old content
  while (contentWrapper.lastChild) {
    contentWrapper.removeChild(contentWrapper.lastChild);
  }
}

// Capitalize letters
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// Convert lower_snake_case to camelCase
const toCamel = (s) => {
  return s.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const truncate = function(str, maxLength){
     return (str.length <= maxLength) ? str : str.substring(0, maxLength) + '...';
}

const projectName = function(pathname){
     return pathname.split('/')[1];
}

const parameterize = function(string) {
  return string
    .toLowerCase()                           // Convertir en minuscule
    .normalize('NFD')                        // Normaliser pour séparer les caractères accentués
    .replace(/[\u0300-\u036f]/g, '')         // Supprimer les accents
    .replace(/[^a-z0-9\s-]/g, '')            // Supprimer les caractères non-alphanumériques
    .trim()                                  // Supprimer les espaces au début et à la fin
    .replace(/\s+/g, '-')                    // Remplacer les espaces par des tirets
    .replace(/-+/g, '-');                    // Réduire les tirets multiples à un seul
}

export { removeContent, capitalize, toCamel, truncate, projectName, parameterize };

