import { Controller } from "@hotwired/stimulus"
import Pickr from '@simonwep/pickr';
import '@simonwep/pickr/dist/themes/classic.min.css';   // 'classic' theme
import '@simonwep/pickr/dist/themes/monolith.min.css';  // 'monolith' theme
import '@simonwep/pickr/dist/themes/nano.min.css';      // 'nano' theme

export default class extends Controller {
  static targets = ['picker', 'input']

  connect(){
    if (!this.hasPickerTarget) return;
    if (this.pickr) return;

    this.pickr = Pickr.create({
        el: this.pickerTarget,
        theme: 'nano', // or 'monolith', or 'nano'
        appClass: 'tag-color-picker',
        defaultRepresentation: 'HEX',
        closeWithKey: 'Escape',
        position: 'bottom-start',
        silent: false,

        swatches: [
          '#fff',
          '#000',
          '#3797d2',
          '#7193e3',
          '#ae8ae4',
          '#27c7bd',
          '#47c399',
          '#6dbc72',
          '#90b14e',
          '#b2a332',
          '#e47cd1',
          '#ff71ad',
          '#ff747e',
          '#ff884b',
          '#ffa600',
          '#d2902b',
          '#ed783d',
          '#ff5d5d'
        ],

        components: {

            // Main components
            preview: true,
            opacity: false,
            hue: true,

            // Input / output Options
            interaction: {
                hex: false,
                rgba: false,
                hsla: false,
                hsva: false,
                cmyk: false,
                input: true,
                cancel: false,
                save: false
            }
        }
    });

    var updateColorPickr = () => {
      const selectedColor = this.pickr.getColor().toHEXA()
      this.pickr.setColor(selectedColor.join(''))
      this.inputTarget.value = selectedColor;
      this.pickr.hide();
      // Trigger the event to change color of related elements when the picker is used as part of a colored element component
      const customEvent = new CustomEvent('update-element-color', { detail: { color: selectedColor.toString(), pickerElement: this.element } });
      window.dispatchEvent(customEvent);
    }

    this.pickr.on('init', instance => {
      const initColor = (this.element.dataset.color) ? this.element.dataset.color: '#3797D2'
      this.pickr.setColor(initColor)
      this.inputTarget.value = initColor;
    })

    this.pickr.on('changestop', (source, instance) => {
      updateColorPickr();
    })

    this.pickr.on('swatchselect', (source, instance) => {
      updateColorPickr();
    })

    this.pickr.on('show', (source, instance) => {
      // Check if the pickr is inside a dropdown and freeze the dropdown to prevent it to close
      const dropdown = event.currentTarget.closest('[data-tippy-root]')
      if (dropdown === null) return;

      const button = Array.from(dropdown.parentElement.children).filter(el => el.dataset.tooltip === 'dropdown')[0]
      this.tippyInstance = button._tippy;
      this.tippyInstance.setProps({ hideOnClick: false});
    })

    this.pickr.on('hide', (source, instance) => {
      // Check if the pickr is inside a dropdown and freeze the dropdown to prevent it to close
      if (this.tippyInstance === undefined) return;

      this.tippyInstance.setProps({ hideOnClick: true});
    })
  }
}