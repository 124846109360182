import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ['messagesControls', 'conversationsControls', 'input', 'closingBanner']

  // Event handlers
  showMessageControlsHandler = () => this.showMessagesControl(event.detail.closed);
  showConversationControlsHandler = () => this.hideMessagesControl();
  closeConversationHandler = (event) => this.closeConversation(event.detail);

  connect(){
    window.addEventListener('show-message-controls', this.showMessageControlsHandler)
    window.addEventListener('show-conversation-controls', this.showConversationControlsHandler)
    window.addEventListener('close-conversation', this.closeConversationHandler)
  }

  disconnect(){
    window.removeEventListener('show-message-controls', this.showMessageControlsHandler)
    window.removeEventListener('show-conversation-controls', this.showConversationControlsHandler)
    window.removeEventListener('close-conversation', this.closeConversationHandler)
  }

  showMessagesControl(closed){
    this.messagesControlsTarget.classList.remove('hidden')
    if (closed) {
      this.closingBannerTarget.classList.remove('hidden')
    } else {
      this.closingBannerTarget.classList.add('hidden')
    }
    this.conversationsControlsTarget.classList.add('hidden')
    this.inputTarget.value = ''
    this.inputTarget.style.height = 'auto'
    this.inputTarget.focus()
  }

  hideMessagesControl(){
    this.messagesControlsTarget.classList.add('hidden')
    this.closingBannerTarget.classList.add('hidden')
    this.conversationsControlsTarget.classList.remove('hidden')
  }

  createMessage(){
    const createMessageEvent = new CustomEvent("create-message", { detail: { description: this.inputTarget.value } });
    window.dispatchEvent(createMessageEvent);

    this.inputTarget.value = '';
  }

  keyUp(){
    event.preventDefault();

    if (!event.shiftKey && event.key === 'Enter') this.createMessage()
  }

  closeConversation(){
    this.closingBannerTarget.classList.remove('hidden')
  }
}