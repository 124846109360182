import { Controller } from "@hotwired/stimulus"
import ChartJs from '../../../../../components/initialize_graph';

export default class extends Controller {
  static targets = ['chart']

  connect(){
    if (!this.hasChartTarget) return // Means that there is not data with the selected filters
    
    // Initialize Chart
    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    const historyChart = new ChartJs(this.chartTarget, graphData);
    historyChart.drawChart();
  }

  closePanel(){
    this.element.classList.add('hidden')

    // Reset page
    const resetEvent = new CustomEvent("reset-page");
    window.dispatchEvent(resetEvent);

    // Unselect ideas from the verbatim list to hide the action bar
    const listSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_cluster_panel_clustered_ideas_list', type: 'ideas', selected_ids: [] } });
    window.dispatchEvent(listSelectionEvent);
  }
}
