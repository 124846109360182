import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['precisionColumn', 'tagColumn', 'aiAgentColumn', 'clusterFieldColumn']
  
  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  tabUpdatedHandler = (event) => this.showModeColumn(event.detail.mode)
  validatedOnlySwitchToggleHandler = (event) => this.toggleValidatedRows()
  screenSizeToggledHandler = (event) => this.updateTableAfterResizing(event.detail.screen)
  tableUpdatedHandler = (event) => this.rebuildTable(event.detail.data);
  topicClickedHandler = (event) => this.highlightRows()
  filterDataUpdatedHandler = (event) => this.rebuildTable(event.detail.data);

  connect(){
    // Hide cluster fields column if the size of the screen is set to small
    this.updateTableAfterResizing(this.pageState().screen) 
    this.showModeColumn(this.pageState().mode)

    // Hide validated rows when activated
    if (this.pageState().validated_only) this.toggleValidatedRows()

    // Show table if page_state ok
    if (this.pageState().display === 'table' && this.pageState().scale === 'micro') this.element.classList.remove('hidden')

    // Event Listeners
    window.addEventListener('tab-updated', this.tabUpdatedHandler)
    window.addEventListener('validated-only-switch-toggled', this.validatedOnlySwitchToggleHandler)
    window.addEventListener('screen-size-toggled', this.screenSizeToggledHandler)
    window.addEventListener('table-updated', this.tableUpdatedHandler)
    window.addEventListener('topic-clicked', this.topicClickedHandler)
    window.addEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
  }

  // Remove Event listeners if the controller is removed
  disconnect(){
    window.removeEventListener('tab-updated', this.tabUpdatedHandler)
    window.removeEventListener('validated-only-switch-toggled', this.validatedOnlySwitchToggleHandler)
    window.removeEventListener('screen-size-toggled', this.screenSizeToggledHandler)
    window.removeEventListener('table-updated', this.tableUpdatedHandler)
    window.removeEventListener('topic-clicked', this.topicClickedHandler)
    window.removeEventListener('filtered-data-updated', this.filterDataUpdatedHandler)
  }

  toggleValidatedRows(){
    let hiddenRowCount = 0
    const rows = this.element.querySelectorAll(`tr[id^=clusters-]`)

    rows.forEach(row => {
      const isValidated = row.querySelector('[data-cluster-state]').dataset.clusterState === 'validated'
      const metric = row.querySelector('[data-metric]').dataset.metric
      const isInMetricRange = (metric >= this.pageState().metric_range[0] && metric <= this.pageState().metric_range[1])

      if ((this.pageState().validated_only && !isValidated) || (!isInMetricRange)){
        row.classList.add('hidden')
        hiddenRowCount += 1
      } else {
        row.classList.remove('hidden')
      }
    })

    // Update list count in table
    const clusterList = document.querySelector('#clusters_index_clusters_list')
    const totalCount = JSON.parse(clusterList.dataset.data).selection.results_count
    const listBanner = clusterList.querySelector('[data-list-target="selectionBanner"] span')
    listBanner.innerHTML = listBanner.innerText.replace(/^[0-9]*/g, totalCount - hiddenRowCount)
  }

  updateTableAfterResizing(size){
    if (size === 'big') {
      this.clusterFieldColumnTargets.forEach(field => field.classList.remove('hidden'))
    } else {
      this.clusterFieldColumnTargets.forEach(field => field.classList.add('hidden'))
    }
  }

  showModeColumn(mode){
    this.tagColumnTargets.forEach(field => field.classList.add('hidden'))
    this.precisionColumnTargets.forEach(field => field.classList.add('hidden'))
    this.aiAgentColumnTargets.forEach(field => field.classList.add('hidden'))

    if (mode === 'topics'){
      this.tagColumnTargets.forEach(field => field.classList.remove('hidden'))
    } else if (mode === 'ai'){
      this.precisionColumnTargets.forEach(field => field.classList.remove('hidden'))
    } else if (mode === 'ai_agents'){
      this.aiAgentColumnTargets.forEach(field => field.classList.remove('hidden'))
    }
  }

  rebuildTable(data){
    if (this.pageState().scale !== 'micro' || this.pageState().display !== 'table') {
      this.element.classList.add('hidden')
    }

    if (this.pageState().scale !== 'micro') return;

    if (!Object.keys(data).includes('Topics::MicroTableComponent')) return;

    this.element.insertAdjacentHTML('afterend', data['Topics::MicroTableComponent'])
    this.element.remove();

    // Reselect the list based on selected_ids. Add a timeout to let the new list being integrated into the DOM
    setTimeout(() => {
      this.highlightRows()
    }, 300)
  }

  highlightRows(){
    const listSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_clusters_list', type: 'clusters', selected_ids: this.pageState().selected_ids } });
    window.dispatchEvent(listSelectionEvent);
  }

  selectRow(){
    const id = parseInt(event.currentTarget.closest('tr').id.replace('clusters-', ''), 10)

    const stateEvent = new CustomEvent("state-updated", { detail: { right_panel: id } });
    window.dispatchEvent(stateEvent);

    const topicPanelEvent = new CustomEvent("topic-panel-updated");
    window.dispatchEvent(topicPanelEvent);
    
    if (this.pageState().screen === 'small') return;

    // If the right panel is hidden because the graph size is 'big', then dispatch an event that will be captured by the left_panel_controller.js in order to decrease the graph size
    const toggleScreenEvent = new CustomEvent("toggle-screen-size");
    window.dispatchEvent(toggleScreenEvent);
  }
}
