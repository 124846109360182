import { Controller } from "@hotwired/stimulus"
import initializeSelect from "../../components/initialize_select";

export default class extends Controller {
  static targets = ['typeBtn', 'agentTypeField', 'customerBotSourceWrapper', 'customerBotSelect', 
    'assistantBotSourceWrapper', 'assistantBotSelect', 'submitBtn']

  connect(){
    this.initializeConnectorSelect(this.customerBotSelectTarget)
    this.initializeConnectorSelect(this.assistantBotSelectTarget)
  }

  initializeConnectorSelect(selector){
    initializeSelect({
      selector: selector,
      otherSettings: {
        options: JSON.parse(selector.dataset.options),
        items: JSON.parse(selector.dataset.items),
        render: {
          item: function(data, escape) {
            return `<div class="flex justify-start items-center gap-6">
                      <div class="flex p-2 rounded bg-gray-100 border shadow">
                        <img class="w-6" src="${data.icon}">
                      </div>
                      <div class="">${escape(data.text)}</div>
                    </div>`
          },
          option: function(data, escape) {
            return `<div class="flex justify-start items-center gap-6">
                      <div class="flex p-2 rounded bg-gray-100 border shadow">
                        <img class="w-6" src="${data.icon}">
                      </div>
                      <div class="">${escape(data.text)}</div>
                    </div>`
          },
        }
      }
    });
  }

  selectType(){
    const selectedType = event.currentTarget.dataset.type;

    // Change button design
    this.typeBtnTargets.forEach(btn => {
      if (selectedType === btn.dataset.type){
        btn.classList.add('display-btn-selected')
        btn.classList.remove('display-btn')
      } else {
        btn.classList.remove('display-btn-selected')
        btn.classList.add('display-btn')
      }
    })

    // Show/Hide sourceWrapper
    if (selectedType === 'deflector'){
      this.customerBotSourceWrapperTarget.classList.remove('hidden')
      this.customerBotSelectTarget.tomselect.enable()
    } else {
      this.customerBotSourceWrapperTarget.classList.add('hidden')
      this.customerBotSelectTarget.tomselect.disable()
    }

    if (selectedType === 'assistant'){
      this.assistantBotSourceWrapperTarget.classList.remove('hidden')
      this.assistantBotSelectTarget.tomselect.enable()
    } else {
      this.assistantBotSourceWrapperTarget.classList.add('hidden')
      this.assistantBotSelectTarget.tomselect.disable()
    }

    // Update hidden field
    this.agentTypeFieldTarget.value = selectedType

    this.updateSubmitBtnState()
  }

  updateSubmitBtnState(){
    if (this.agentTypeFieldTarget.value === '') {
      this.submitBtnTarget.classList.add('btn-disabled')
      return
    }

    if (this.agentTypeFieldTarget.value === 'deflector' && this.customerBotSelectTarget.value === ''){
      this.submitBtnTarget.classList.add('btn-disabled')
      return
    }

    if (this.agentTypeFieldTarget.value === 'assistant' && this.assistantBotSelectTarget.value === ''){
      this.submitBtnTarget.classList.add('btn-disabled')
      return
    }

    this.submitBtnTarget.classList.remove('btn-disabled')
  }
}