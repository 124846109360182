import { Controller } from "@hotwired/stimulus"
import { projectName, removeContent } from "../components/utilities"
import setTooltips from '../components/app_tooltips';
import initializeFlatpickr from "../components/flatpickr_date";
import MicroModal from 'micromodal';

export default class extends Controller {
  static targets = ['loaderTpl', 'content']

  connect(){
    this.type = this.element.dataset.type;
  }

  // Triggered by the custom event "fetch-modal-content"
  fetchModalContent(){
    let [type, content, id] = event.detail.content.split('.')
    if (type !== this.type) return;
    
    this.reinitializeModal()

    let url;
    switch (content){
    case 'catalog':
      url = `${window.location.origin}/${projectName(window.location.pathname)}/connectors/connectors/catalog?type=${type}`
      this.requestContent(url)
      break;
    case 'connector_skeleton':
      url = `${window.location.origin}/${projectName(window.location.pathname)}/connectors/connectors/${id}/connector_skeleton_list/?type=${type}`
      this.requestContent(url)
      break;
    case 'connector':
    }
  }

  requestContent(url){
    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin"
    })
    .then(response => response.json())
    .then(data => {
      this.updateModal(data)
    });
  }

  reinitializeModal(){
    removeContent(this.contentTarget);
    this.contentTarget.insertAdjacentHTML('beforeend', this.loaderTplTarget.innerHTML)
  }

  updateModalAfterAjax(){
    const [_data, _status, xhr] = event.detail;
    const data = JSON.parse(xhr.response)

    this.updateModal(data)
    setTooltips(this.element)

    // Initialize Flatpickr date
    const dateFields = this.element.querySelectorAll('input[type="date"]');
    dateFields.forEach(dateField => initializeFlatpickr(dateField))
    const datetimeFields = this.element.querySelectorAll('input[type="datetime-local"]');
    datetimeFields.forEach(datetimeField => initializeFlatpickr(datetimeField, { enableTime: true, dateFormat: "Y-m-d H:i"}))
  }

  updateModal(data){
    if (data['type'] !== this.type) return;

    if (data['content']){
      removeContent(this.contentTarget);
      this.contentTarget.insertAdjacentHTML('afterbegin', data['content'])
      setTooltips(this.contentTarget)
    } else if (data['instruction'] === 'close_modal') {
      MicroModal.close(this.element.id);
    }

    // Flash message
    if (data['flash']){
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    }
  }
}