import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['btn', 'snippet'];

    connect(){
    }

    changeSnippet(){
        // Remove the selected class from all the buttons
        this.btnTargets.forEach(target => target.classList.replace('display-btn-selected', 'display-btn'))
    
        // Then add the selected class to the current button
        event.currentTarget.classList.add('display-btn-selected')

        // Hide all snippets
        this.snippetTargets.forEach(target => target.classList.toggle('hidden'))
    }
}