import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input', 'saveBtn', 'errorFilesList', 'errorFilesFormatWrapper'];

  checkFileExtension() {
    if (this.inputTarget.files.length === 0) return;
    
    const acceptedFormats = ['txt', 'md', 'html', 'htm', 'docx', 'pdf'];

    // Get invalid files
    const wrongFilesNames = Array.from(this.inputTarget.files)
      .filter(file => !acceptedFormats.includes(file.name.split('.').pop().toLowerCase()))
      .map(file => `<li>${file.name}</li>`);

    if (wrongFilesNames.length === 0) {
      this.saveBtnTarget.classList.remove('btn-disabled');
      this.errorFilesFormatWrapperTarget.classList.add('hidden');
      this.errorFilesListTarget.innerHTML = ""; // Clear errors
    } else {
      this.saveBtnTarget.classList.add('btn-disabled');
      this.errorFilesListTarget.innerHTML = wrongFilesNames.join(""); // Set error list
      this.errorFilesFormatWrapperTarget.classList.remove('hidden');
    }    
  }
}
