import { Controller } from "@hotwired/stimulus"
import { projectName } from '../components/utilities';

export default class extends Controller {
  // Export cluster ideas (from cluster index)
  exportSelectedIdeasXLS(){
      const url = `${window.location.origin}/${projectName(window.location.pathname)}/ideas/export_selected_data.xlsx?`
      this.exportXLS(url)
  }

  // Export selected conversations 
  exportSelectedConversationsXLS() {
      const url = `${window.location.origin}/${projectName(window.location.pathname)}/conversations/export_selected_data.xlsx?`
      this.exportXLS(url)
  }

  exportSelectedClustersXLS() {
      const url = `${window.location.origin}/${projectName(window.location.pathname)}/clusters/export_selected_data.xlsx?`
      this.exportXLS(url)
  }

  exportXLS(url){
    const listId = event.currentTarget.dataset.listId

    const params = {
        list_id: listId,
        data: JSON.parse(event.currentTarget.dataset.data)
    }

    url += new URLSearchParams({export_params: JSON.stringify(params)}); 

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
      .content.value;
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/js",
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      },
      credentials: "same-origin"
    })
    .then(response => response.json())
    .then(data => {
        document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);

        // Reset list selection
        const resetListEvent = new CustomEvent("list-selection-updated", { detail: { list_id: listId, type: 'ideas', selected_ids: [] } });
        window.dispatchEvent(resetListEvent);
    })
  }
}
