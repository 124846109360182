import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  updateModalAfterAjax(){
    const [_data, _status, xhr] = event.detail;
    const data = JSON.parse(xhr.response)

    if (data['component']) {
      // Send event to update the article card component
      const updateArticleTitleEvent = new CustomEvent("article-card-updated", { detail: { articleId: data['article_id'], component: data['component'] } });
      window.dispatchEvent(updateArticleTitleEvent);

      // Close the modal
      this.closeModal();
    }

    // Flash message
    if (data['flash']){
      document.querySelector('[data-controller="flash-init"]').dataset.content = JSON.stringify(data['flash']);
    }
  }

  closeModal(){
    const modalId = event.currentTarget.closest('[data-article-target="modal"]').id;
    MicroModal.close(modalId);
  }
}
