import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  showUnresolvedPanel(){
    const stateEvent = new CustomEvent("state-updated", { detail: { unresolved_panel_visible: true } });
    window.dispatchEvent(stateEvent);

    const articlePanelEvent = new CustomEvent("unresolved-panel-updated");
    window.dispatchEvent(articlePanelEvent);
  }

  openModal(){
		const modalId = event.currentTarget.dataset.id;
		MicroModal.show(modalId);
	}
}
