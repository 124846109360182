import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  connect(){
  }

  openConversation(){
    // Triggers an event that will be captured by chat_window_controller.js in order to show the chat messages component
    const conversationId = event.currentTarget.dataset.conversationId

    const openChatEvent = new CustomEvent("open-chat-conversation", { detail: { conversation_id: conversationId } });
    window.dispatchEvent(openChatEvent);
  } 

}