import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button']

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  //Event handlers
  toggleTabsHandler = (event) => this.modifyTabVisibility()

  connect(){
    window.addEventListener('toggle-tabs', this.toggleTabsHandler)
  }

  disconnect(){
    window.removeEventListener('toggle-tabs', this.toggleTabsHandler)
  }

  switchTab(){
    const currentMode = JSON.parse(document.querySelector('[data-page-state]').dataset.pageState).mode
    const newMode = event.currentTarget.dataset.mode;
    if(currentMode === newMode) return;

    this.buttonTargets.forEach(btn => btn.classList.remove('selected'))
    event.currentTarget.classList.add('selected')

    // Unselect ideas from the verbatim list to hide the action bar
    const listSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_cluster_panel_clustered_ideas_list', type: 'ideas', selected_ids: [] } });
    window.dispatchEvent(listSelectionEvent);
    // Unselect ideas from the ai verbatim list to hide the action bar (when in AI panel and going to verbatim tab -> need to reset the action bar)
    const aiListSelectionEvent = new CustomEvent("list-selection-updated", { detail: { list_id: 'clusters_index_ai_list', type: 'ideas', selected_ids: [] } });
    window.dispatchEvent(aiListSelectionEvent);

    const stateEvent = new CustomEvent("state-updated", { detail: { mode: newMode } });
    window.dispatchEvent(stateEvent);

    const tabEvent = new CustomEvent("tab-updated", { detail: { mode: newMode } });
    window.dispatchEvent(tabEvent);
  }

  modifyTabVisibility(){
    if (this.pageState().scale === 'micro'){
      this.buttonTargets.filter(btn => ['ai', 'ai_agents'].includes(btn.dataset.mode))
                        .forEach(btn => btn.classList.remove('btn-disabled'))
    } else {
      this.buttonTargets.forEach(btn => btn.classList.remove('selected'))
      this.buttonTargets.find(btn => btn.dataset.mode === 'topics').classList.add('selected')

      this.buttonTargets.filter(btn => ['ai', 'ai_agents'].includes(btn.dataset.mode))
                        .forEach(btn => btn.classList.add('btn-disabled'))
    }
  }
}
