import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../components/utilities';
import setTooltips from '../../components/app_tooltips';

export default class extends Controller {
  static targets = ['button', 'timeSelector', 'content']

  updateSquadContent(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/ai_agents/filter_squad`;

    const body = { time_frame: event.currentTarget.value, time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.contentTarget)
      this.contentTarget.insertAdjacentHTML('afterbegin', data['squad_content'] );

      setTooltips(this.contentTarget);
    })
  }
}
