import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  // To activate the action bar, first fill an entry into action_bar_component.rb
  // Then add a data-"controller_name" to the element containing data-controller="controller_name"
  // Finally add the controller_name to the targets array

  static targets = ["button"];

  // Event handlers
  listActionUpdatedHandler = (event) => this.updateListActions(event.detail);
  resetListActionsHandler = (event) => this.resetListActions(event.detail.list_action_id);

  connect(){
    window.addEventListener('list-action-button-updated', this.listActionUpdatedHandler)
    window.addEventListener('reset-list-actions', this.resetListActionsHandler)
  }
  
  disconnect(){
    window.removeEventListener('validated-only-switch-toggled', this.listActionUpdatedHandler)
    window.removeEventListener('reset-list-actions', this.resetListActionsHandler)
  }

  updateListActions(data){
    if (this.element.id !== data.list_action_id) return

    this.buttonTargets.forEach(button => {
      button.dataset.listId = data.list_id
      button.dataset.data = JSON.stringify(data.data)
      if (button.dataset.needSelection !== "true") return;

      const selectionRange = JSON.parse(button.dataset.selectionRange);
      const selectedCount = data.data.selection.selected_count;
      const inSelectionRange = this.inSelectionRange(selectedCount, selectionRange);
  
      if (inSelectionRange){
          button.classList.remove('opacity-25', 'cursor-not-allowed');
          button.classList.add('cursor-pointer', 'opacity-75', 'hover:opacity-100');
      } else {
          button.classList.add('opacity-25', 'cursor-not-allowed');
          button.classList.remove('cursor-pointer', 'opacity-75', 'hover:opacity-100');
      }
    })

    // Show or hide the action bar but only for conversations and ideas list selection
    if (this.element.id !== 'clusters-index-list-bar') this.toggleActionBarVisibility(); 
    // For cluster-index-list-bar, the bar is triggered but the list_controller calling clusterPanelController().toggleClusterBulkActionButtonsPanel() 
  }

  resetListActions(listActionId){
    if (this.element.id !== listActionId) return

    this.buttonTargets.forEach(button => {
      button.removeAttribute('list_id')
      button.removeAttribute('data')

      if (button.dataset.needSelection === "true") {
        button.classList.add('opacity-25', 'cursor-not-allowed');
        button.classList.remove('cursor-pointer', 'opacity-75', 'hover:opacity-100');
      } else {
        button.classList.remove('opacity-25', 'cursor-not-allowed');
        button.classList.add('cursor-pointer', 'opacity-75', 'hover:opacity-100');
      }
    })

    // Show or hide the action bar but only for conversations and ideas list selection
    if (this.element.id !== 'clusters-index-list-bar') this.toggleActionBarVisibility(); 
  }

  toggleActionBarVisibility(){
    // Check if at least one of the buttons is active, if so, show the action bar
    const activeButtons = this.buttonTargets.some((button) => button.classList.contains('cursor-pointer'));
    if (activeButtons) {
      // Show the action bar 
      this.element.classList.remove('hidden');
      this.element.style.animation = "barAppear 0.5s ease-in-out";
    } else if (!this.element.classList.contains('hidden')){
      // Hide the action bar when the animation is over
      this.element.style.animation = "barDisappear 0.3s ease-in-out";

      const handleAnimationEnd = () => {
        this.element.classList.add('hidden');

        this.element.removeEventListener("animationend", handleAnimationEnd);
      };

      this.element.addEventListener("animationend", handleAnimationEnd);
    }
  }

  //  used by toDoOnChange(connect) and checkActivation method - return a boolean
  inSelectionRange(selectedCount, selectionRange) {
    const aboveMinRange = selectionRange[0] == null ? true : (selectionRange[0] <= selectedCount);
    const belowMaxRange = selectionRange[1] == null ? true : (selectedCount <= selectionRange[1]);

    if ((aboveMinRange) && (belowMaxRange)) {
      return true;
    } else {
      return false;
    }
  }

  selectAction() {
    event.preventDefault();
    let selectedAction = document.querySelector('[list-actions-selected]');
    if (event.currentTarget.dataset.toggleClicked === "true") {
        if (selectedAction != null) {
            selectedAction.classList.add('opacity-75');
            selectedAction.removeAttribute('list-actions-selected');
        }
        if (event.currentTarget !== selectedAction) {
            event.currentTarget.classList.remove('opacity-75');
            event.currentTarget.setAttribute('list-actions-selected', '');
        }
    }

    // Connect to the inner controller
    const controllerName = event.currentTarget.dataset.redirController;
    const actionName = event.currentTarget.dataset.redirAction;
    const controllerDivId = event.currentTarget.dataset.controllerDivId

    // Find the corresponding controller
    const controller = this.redirController(controllerName, controllerDivId);

    // Call the action
    // Use indirect eval to avoid esbuild warning (https://esbuild.github.io/content-types/#direct-eval)
    if (this.checkActivation()) (0,eval)(controller[actionName]())

    // We could also use this method --> to be assess in a near future
    // https://discourse.stimulusjs.org/t/having-an-outer-controller-that-controls-inner-controller/136/3
  }

  redirController(controllerName, controllerDivId) {
    const element = document.querySelector(`[data-list-action-locator*='${controllerDivId}']`)
    return this.application.getControllerForElementAndIdentifier(element, controllerName)
  }

  // Check if the action can be done
  checkActivation(){
    const needSelection = event.currentTarget.dataset.needSelection === "true";
    const data = JSON.parse(event.currentTarget.dataset.data)

    const selectionRange = JSON.parse(event.currentTarget.dataset.selectionRange);
    const selectedCount = data.selection.selected_count;
    const inSelectionRange = this.inSelectionRange(selectedCount, selectionRange);

    if (!needSelection || (inSelectionRange)) return true;
    return false;
  }

  updateSelectedCluster(selectedCluster=null){
    this.buttonTargets.forEach(el => {
      el.dataset.selectedCluster = (selectedCluster === null) ? '' : selectedCluster
    })
  }
}
