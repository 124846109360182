import { Controller } from "@hotwired/stimulus"
import { removeContent } from '../../components/utilities';



export default class extends Controller {

  static targets = ["conversationsPanel", "messagesPanel", "centralPanel", "header", "footer", "controlPanel", "backBtn"]

  chatState(){
    return JSON.parse(document.querySelector('[data-chat-state]').dataset.chatState)
  }

  // Event handlers
  newChatConversationHandler = () => this.loadChatMessagesPanel();
  loadSelectedConversationHandler = (event) => this.loadChatMessagesPanel(event.detail.conversation_id);
  conversationsListUpdatedHandler = (event) => this.refreshConversationsPanel(event.detail.conversationList);
  openChatWindowHandler = (event) => this.setCentralPanelHeight();
 
  connect(){
    // Event Listeners
    window.addEventListener('new-chat-conversation', this.newChatConversationHandler)
    window.addEventListener('open-chat-conversation', this.loadSelectedConversationHandler)
    window.addEventListener('conversations-list-updated', this.conversationsListUpdatedHandler)
    window.addEventListener('open-chat-window', this.openChatWindowHandler)
    window.addEventListener("resize", this.openChatWindowHandler)

    // If there are no conversations show the messages panel
    const conversationsCount = this.element.dataset.conversationsCount

    if( conversationsCount === '0' && this.element.dataset.mode !== 'full_screen') {
      this.loadChatMessagesPanel()
    }
  }

  // Remove Event listeners if the controller is removed
  disconnect(){
    window.removeEventListener('new-chat-conversation', this.newChatConversationHandler)
    window.removeEventListener('open-chat-conversation', this.loadSelectedConversationHandler)
    window.removeEventListener('conversations-list-updated', this.conversationsListUpdatedHandler)
    window.removeEventListener('open-chat-window', this.openChatWindowHandler)
    window.removeEventListener("resize", this.openChatWindowHandler)
    window.removeEventListener("resize-input", this.resizeInputHandler)
  }

  setCentralPanelHeight(){
    if (!this.hasCentralPanelTarget) return;
    
    if (window.innerHeight > 880){
      this.centralPanelTarget.style.height = '70vh'
    } else {
      const newHeight = window.innerHeight - this.headerTarget.clientHeight - this.footerTarget.clientHeight - this.controlPanelTarget.clientHeight - 120
      this.centralPanelTarget.style.height = `${newHeight}px`
    }
  }

  newConversation(){
    // Triggers an event that will be captured by chat_window_controller.js in order to show the chat messages component
    const newConversationEvent = new CustomEvent("new-chat-conversation");
    window.dispatchEvent(newConversationEvent);
  }
  
  loadChatMessagesPanel(conversationId){
    // Ajax call to retrieve the Chat Messages component
    const url = `${window.location.origin}/connectors/chats/messages/list`
    const body = { 
      ...this.chatState(),
      conversation_id: conversationId
    }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      this.messagesPanelTarget.innerHTML = data.messages
      this.messagesPanelTarget.classList.remove('hidden')

      if (this.element.dataset.mode !== 'full_screen'){
        this.conversationsPanelTarget.classList.add('hidden')
        this.backBtnTarget.classList.remove('hidden')

        const showControlsEvent = new CustomEvent("show-message-controls", { detail: { closed: data.closed } });
        window.dispatchEvent(showControlsEvent);
      }
    });
  }

  refreshConversationsPanel(conversationsComponent){
    // Remove former content
    removeContent(this.conversationsPanelTarget);

		// Insert new component
		this.conversationsPanelTarget.insertAdjacentHTML('beforeend', conversationsComponent);
  }

  closeMessagesPanel(){
    this.conversationsPanelTarget.classList.remove('hidden');
    this.messagesPanelTarget.classList.add('hidden');
    this.backBtnTarget.classList.add('hidden');
    this.setCentralPanelHeight()

    const showControlsEvent = new CustomEvent("show-conversation-controls");
    window.dispatchEvent(showControlsEvent);
  }

  resizeInput(){
    const input = event.currentTarget;

    if (this.element.dataset.mode === 'full_screen'){
      input.style.height = 'auto';
      input.style.height = `${input.scrollHeight}px`;
      return;
    }

    const workingPanelHeight = this.centralPanelTarget.clientHeight + this.controlPanelTarget.clientHeight;
    input.style.height = 'auto'; // Reset the height to auto to shrink

    const textareaHeight = Math.min(input.scrollHeight, workingPanelHeight);

    // Ajust the height of messages list container
    // Remove 16 due to padding set for the parent container of the textarea
    const newcentralPanelHeight = workingPanelHeight - textareaHeight - 32;
    this.centralPanelTarget.style.height = `${newcentralPanelHeight}px`;

    // Applique la nouvelle hauteur au textarea
    input.style.height = `${textareaHeight}px`;
  }
}
