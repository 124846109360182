import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../../components/utilities';
import initializeTooltips from '../../../components/app_tooltips';
import ChartJs from "../../../components/initialize_graph";

export default class extends Controller {
  static targets = ['chart'];

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  // Event handlers
  timeFrameUpdatedHandler = () => this.loadMetric()

  connect(){
    window.addEventListener('time-frame-updated', this.timeFrameUpdatedHandler)

    this.loadMetric()
  }

  disconnect() {
    window.removeEventListener('time-frame-updated', this.timeFrameUpdatedHandler)
  }

  loadMetric(){
    const url = `${window.location.origin}/${projectName(window.location.pathname)}/ai_agents/metric`;

    const body = { type: this.element.dataset.type, page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.element)
      const panelName = Object.keys(data).find(key => key.includes('Component'))
      const panel = data[panelName]
      this.element.insertAdjacentHTML('afterbegin', panel );

      this.initializeChart();
      initializeTooltips(this.element);
    })
  }

  initializeChart(){  
    if (!this.hasChartTarget) return;
    
    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    this.historyChart = new ChartJs(this.chartTarget, graphData);
    this.historyChart.drawChart();
  }
}