import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["inputWebsiteUrl", "inputUrlsList", "submitBtn"];

  connect() {
    this.validateInputs(); // Validate on page load (if prefilled)
  }

  validateInputs() {
    const websiteUrl = this.inputWebsiteUrlTarget.value.trim();
    const urlsList = this.inputUrlsListTarget.value.trim();

    const websiteUrlValid = websiteUrl === "" || this.validateUrl(websiteUrl);
    const urlsListValid = urlsList === "" || this.validateUrlsList(urlsList);

    // Enable submit button if at least one field is valid and no field contains invalid data
    if ((websiteUrlValid && urlsListValid) && (websiteUrl !== "" || urlsList !== "")) {
      this.submitBtnTarget.classList.remove("btn-disabled");
    } else {
      this.submitBtnTarget.classList.add("btn-disabled");
    }
  }

  validateUrl(url) {
    // Check if URL is valid using regex
    const urlPattern = /^(https?:\/\/)?([\w.-]+\.[a-zA-Z]{2,})(:\d{1,5})?(\/\S*)?$/;
    return urlPattern.test(url);
  }

  validateUrlsList(urls) {
    if (urls === "") return true; // ✅ Empty is allowed
    const urlArray = urls.split(",").map(url => url.trim());
    return urlArray.every(url => this.validateUrl(url)); // ✅ Ensure all URLs are valid
  }

  handleWebsiteUrlChange() {
    this.validateInputs();
  }

  handleUrlsListChange() {
    this.validateInputs();
  }
}