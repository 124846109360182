import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['editButton', 'editPanel']

  // Event handlers
  updateElementColorHandler = (event) => this.changeColor(event.detail.color, event.detail.pickerElement);

  connect(){
    window.addEventListener('update-element-color', this.updateElementColorHandler)
  }

  showEditPanel(){
    this.editPanelTarget.classList.remove('hidden')
    this.editButtonTarget.classList.add('hidden')
  }

  hideEditPanel(){
    this.editPanelTarget.classList.add('hidden')
    this.editButtonTarget.classList.remove('hidden')
  }

  changeColor(color, pickerElement){
    // Identify the pickerContainer which is the ancestor div of the picker component that holds the id of both targeted colored element and target input field
    const pickerContainer = pickerElement.closest('[data-targeted-colored-element-id]')

    // Then identify the component for which we will update the background or text color
    const targetedColoredElementId = pickerContainer.dataset.targetedColoredElementId
    const targetedColoredElement = document.querySelector(`#${targetedColoredElementId}`)

    // Check the targeted element type, if it's background then update the backgroundColor, if not, update the text color
    if (targetedColoredElement.dataset.colorType === 'background') {
      targetedColoredElement.style.backgroundColor = color;
    } else {
      targetedColoredElement.style.color = color;
    }

    // Update also the hidden input field with the background color but first identify the id of the hidden field from the same pickerContainer 
    const targetedInputFieldId = pickerContainer.dataset.targetedInputFieldId
    // Then retrieve the hidden field element and update its value
    const targetedInputField = document.querySelector(`#${targetedInputFieldId}`)
    targetedInputField.value = color;

    // Trigger the event to update the chat preview if the colored element is used as part of the cobbai chat connector 
    const customEvent = new CustomEvent('update-chat-preview', { detail: { updatedField: targetedInputField } });
    window.dispatchEvent(customEvent);
  }

  disconnect(){
    window.removeEventListener('update-element-color', this.updateElementColorHandler)
  }
}
