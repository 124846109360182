import { Controller } from "@hotwired/stimulus"
import { removeContent, projectName } from '../../../components/utilities';
import initializeTooltips from '../../../components/app_tooltips';
import ChartJs from "../../../components/initialize_graph";

export default class extends Controller {
  static targets = ['panel', 'chart'];

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  updateMetric(){
    // Update page state
    const stateEvent = new CustomEvent("state-updated", { detail: { selected_automation_id: event.currentTarget.value } });
    window.dispatchEvent(stateEvent);

    const url = `${window.location.origin}/${projectName(window.location.pathname)}/ai_agents/update_split_by_status`;

    const body = { page_state: this.pageState() }

    const csrfToken = document.querySelector('meta[name="csrf-token"]').attributes
        .content.value;
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: "application/js",
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken
        },
        credentials: "same-origin",
        body: JSON.stringify(body)
      })
    .then(response => response.json())
    .then(data => {
      removeContent(this.panelTarget)
      const panelName = Object.keys(data).find(key => key.includes('Component'))
      const panel = data[panelName]
      this.panelTarget.insertAdjacentHTML('afterbegin', panel );

      this.initializeChart();
      initializeTooltips(this.panelTarget);
    })
  }

  initializeChart(){  
    if (!this.hasChartTarget) return;
    
    const graphData = JSON.parse(this.chartTarget.dataset.chart);
    this.historyChart = new ChartJs(this.chartTarget, graphData);
    this.historyChart.drawChart();
  }
}