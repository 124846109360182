import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  pageState(){
    return JSON.parse(document.querySelector('[data-page-state]').dataset.pageState)
  }

  toggleSentimentFilter(){
    // Check if the current button is already clicked
    if (event.currentTarget.classList.contains('display-btn-selected')) {
      event.currentTarget.classList.replace('display-btn-selected', 'display-btn');
    } else {
      event.currentTarget.classList.replace('display-btn', 'display-btn-selected');
    }

    this.addSentimentToFilterBar();
  }

  addSentimentToFilterBar(){
    const selectedSentiments = []

    const selectedBtns = this.element.querySelectorAll('.display-btn-selected')
    selectedBtns.forEach(btn => {
      selectedSentiments.push(btn.dataset.sentiment)
    })

    // Remove former sentiment filter
    const filterBar = document.querySelector('[data-controller="filter-bar"]')
    let filters = JSON.parse(filterBar.dataset.filters)
    filters = filters.filter(el => el['table_id'] !== 'ideas.satisfaction')

    if (selectedSentiments.length !== 0) {
      // Prepare new sentiment filter
      const sentimentFilter = {
        table_id: 'ideas.satisfaction',
        operator: 'contains',
        values: selectedSentiments,
      }

      // Save the new filters
      filters.push(sentimentFilter)
    }

    filterBar.dataset.filters = JSON.stringify(filters)

    const filterEvent = new CustomEvent("filters-updated", { detail: { filters: filters } });
    window.dispatchEvent(filterEvent);
  }
}
